import React from 'react'
import { useSelector } from 'react-redux'
import { CRow, CCol } from '@coreui/react'
import { cashierTypes } from '@/utils/constants'

const VoucherFooter = () => {
  const { cashierType } = useSelector((state) => state.cashCount)
  return (
    <>
      <CRow className="mt-3">
        <CCol lg={12} className="text-center mt-4">
          <small className="">-------------------------------------</small>
        </CCol>
        {cashierType === cashierTypes.Pago && (
          <CCol xs={12} sm={6} className="text-center">
            <small className="">Este documento requiere firma del supervisor</small>
          </CCol>
        )}

        {cashierType === cashierTypes.Tesoreria && (
          <CCol xs={12} sm={6} className="text-center">
            <small className="">Este documento requiere firma</small>
          </CCol>
        )}
      </CRow>
    </>
  )
}

export default VoucherFooter
