import RemittanceBaseService from './remittanceBaseService'

export default class RemittanceCatalogsService extends RemittanceBaseService {
  async getRemittances() {
    return await super.get('catalog/remittances')
  }

  async getIdentificationTypes() {
    return await super.get('catalog/identification-type')
  }

  async getRemittanceStatus() {
    return await super.get('catalog/status')
  }

  async getPicForm(queryParams) {
    return await super.get('catalog/picform', queryParams)
  }

  async getCities(queryParams) {
    return await super.get('catalog/city', queryParams)
  }
}
