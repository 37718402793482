export const remittancePaymentMethods = {
  CASH_PICKUP: 'Efectivo',
}

export const errorEnumForm = {
  pattern: 'Formato inválido',
  required: 'Campo obligatorio',
  minLength: (digits) => `Debe tener un minimo de ${digits} digitos`,
}

export const remittanceStatus = {
  AVAILABLE: 'Available',
  PAID: 'Paid',
  DOWNLOADED: 'Downloaded',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  FAILD: 'Faild',
  CREATED: 'Created',
  REVERSED: 'Reversed',
}

export const remittanceNameMapper = {
  Available: 'Disponible',
  Paid: 'Pagada',
  Downloaded: 'Descargada',
  Rejected: 'Rechazada',
  Cancelled: 'Cancelada',
  Faild: 'Fallida',
  Created: 'Creada',
  Reversed: 'Revertida',
}

export const remittanceDeniedPaidStatus = [
  'Paid',
  'Downloaded',
  'Rejected',
  'Cancelled',
  'Faild',
  'Reversed',
]

export const remittanceColorStatus = {
  Available: 'info',
  Paid: 'success',
  Downloaded: 'warning',
  Rejected: 'danger',
  Cancelled: 'danger',
  Faild: 'danger',
  Created: 'info',
  Reversed: 'dark',
}

export const cashCountRemittanceColumns = [
  {
    key: 'sequence',
    label: 'Consecutivo',
    _props: { className: 'text-end', scope: 'col' },
  },
  {
    key: 'remittance_code',
    label: 'N° Remesa',
    _props: { className: 'text-center', scope: 'col' },
  },
  {
    key: 'beneficiary_name',
    label: 'Beneficiario',
    _props: { className: 'text-center', scope: 'col' },
  },
]

export const cashCountRemittanceReportColumns = [
  {
    key: 'sequence',
    label: 'Consecutivo',
    _props: { className: 'text-end w-10', scope: 'col' },
  },
  {
    key: 'remittance_code',
    label: 'N° Remesa',
    _props: { className: 'text-center w-45', scope: 'col' },
  },
]

export const formObject = {
  status: 200,
  message: 'Successful operation',
  data: {
    id: 1,
    created_date: '2023-10-26T11:08:55.84',
    updated_date: null,
    status: true,
    name: 'PIC From NI',
    country_code: 'NI',
    sections: [
      {
        id: 1,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Perfil Integral Del Cliente',
        description: 'Perfil Integral Del Cliente',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'first_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'middle_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'last_name',
            description: 'Primer apellido',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'last_name2',
            description: 'Segundo apellido',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'identificationType',
            description: 'Tipo de identificación',
            type_form: 'DROPDOWN',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'identification',
            description: 'Identificación',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: true,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'email',
            description: 'Correo',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: true,
            regex: new RegExp(
              "^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@([A-Za-z0-9.-]+).([a-zA-Z]{2,5})$",
              'gm',
            ),
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'phone',
            description: 'Teléfono',
            type_form: 'NUMBER',
            min_length: 8,
            max_length: 8,
            type_value: 'Id',
            apply_regex: false,
            regex: null,
            is_multi_value: false,
            is_required: true,
            values: [],
          },
        ],
      },
      {
        id: 2,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Datos Personales Del Cliente',
        description: 'Datos Personales Del Cliente',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'agtgtr',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'middltrhwdfe_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID Csdffsliente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 3,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Datos Documento De Identificación',
        description: 'Datos Documento De Identificación',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'firssdfft_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'middsxsferle_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'IDthtrh Cliente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 4,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Productos/servicios',
        description: 'Productos/servicios',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'fyjytirst_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'midasdghtdle_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID Clntynsiente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 5,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Datos Sobre Actividad Económica',
        description: 'Datos Sobre Actividad Económica',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'firadst5rest_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'middlgbvse_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID Clrgeqwiente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 6,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Relaciones De Negocios Con Otras Microfinancieras',
        description: 'Relaciones De Negocios Con Otras Microfinancieras',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'firscwdfwt_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'miefwfwddle_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID nbweqCliente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 7,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Referencias',
        description: 'Referencias',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'fiasadrst_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'midyjytrdle_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID asadwe3rCliente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
      {
        id: 8,
        created_date: '2023-10-26T11:26:59.94',
        updated_date: null,
        status: true,
        name: 'Personas Políticamente Expuestas',
        description: 'Personas Políticamente Expuestas',
        pic_form_id: 1,
        keys: [
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'firshtrhret_name',
            description: 'Primer nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'middlethrww_name',
            description: 'Segundo nombre',
            type_form: 'TEXT',
            type_value: 'text',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
          {
            id: 1,
            created_date: '2023-10-26T17:28:57.92',
            updated_date: null,
            status: true,
            pic_form_section_id: 1,
            name: 'ID Cqdewqeqwliente',
            description: 'Cédula',
            type_form: 'TEXT',
            type_value: 'Id',
            apply_regex: false,
            regex: '',
            is_multi_value: false,
            is_required: false,
            values: [],
          },
        ],
      },
    ],
  },
}
