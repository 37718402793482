import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomerContext } from './CustomerContext'

export const CustomerContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState({})

  const value = { customer, setCustomer }

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}

CustomerContextProvider.propTypes = {
  children: PropTypes.node,
}

export default CustomerContextProvider
