import React from 'react'
import { CRow, CCol } from '@coreui/react'

const ReportFooter = () => (
  <>
    <CRow>
      <CCol lg={12} className="text-center">
        <small className="fw-bolder">Para consultas, quejas o sugerencias llamar al:</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="fw-bolder">+ (505) 2264 7484</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="fw-bolder">clientes@credex.com.ni</small>
      </CCol>
    </CRow>
  </>
)

export default ReportFooter
