import BaseService from '../baseService'

export default class AdministrationService extends BaseService {
  baseUrl = 'Cashmax/'

  async getCashierUsers(pagination) {
    return await super.getResource(`GetCashierUsers/`, { ...pagination })
  }

  async getCashiers(pagination) {
    return await super.getResource(`GetCashiers/`, { ...pagination })
  }

  async getCashier(cashieId) {
    return await super.getResource(`Cashier/${cashieId}`)
  }

  async getCashierUser(cashieUserId) {
    return await super.getResource(`CashierUser/${cashieUserId}`)
  }

  async getDenominationForCashier() {
    return await super.getResource(`DenominationForCashier`)
  }

  async getSellingPoints(parameters) {
    return await super.getResource(`GetSellingPoints/`, { ...parameters })
  }

  async getUsers(parameters) {
    return await super.getResource(`GetUsers/`, { ...parameters })
  }

  async getCompanies(parameters) {
    return await super.getResource(`GetCompanies/`, { ...parameters })
  }

  async getTreasuryCashiers() {
    return await super.getResource(`GetTreasuryCashiers/`)
  }

  async updateCashier(cashierId, data) {
    return await super.put(`${this.baseUrl}Cashier/${cashierId}`, data)
  }

  async createCashier(data) {
    return await super.post(`${this.baseUrl}Cashier/`, data)
  }

  async updateCashierUser(cashierUserId, data) {
    return await super.put(`${this.baseUrl}CashierUser/${cashierUserId}`, data)
  }

  async createCashierUser(data) {
    return await super.post(`${this.baseUrl}CashierUser/`, data)
  }
}
