import React from 'react'

import { REMITTANCE_PAYMENTS, REMITTANCE_CASHCOUNT } from './routes'

const RemittancePayments = React.lazy(() =>
  import('@/features/remittances/remittancePayments/RemittancePayments'),
)

const CashCountRemittance = React.lazy(() =>
  import('@/features/remittances/cashCountRemittance/cashCountRemittance'),
)

const remittancesRouter = [
  { path: REMITTANCE_PAYMENTS, name: 'Pago de remesas', element: RemittancePayments },
  { path: REMITTANCE_CASHCOUNT, name: 'Remesas pagadas', element: CashCountRemittance },
]

export default remittancesRouter
