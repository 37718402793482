import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'
import logo from '@/assets/brand/logo-remittance.png'

const VoucherHeader = ({ endowmentType }) => (
  <>
    <CRow>
      <CCol xs={12} sm={6} className="text-center">
        <small className="">Dotación de caja</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} sm={6} className="text-end" style={{ paddingRight: '0.3rem' }}>
        <small className="">Zü Cash</small>
      </CCol>
      <CCol xs={5} sm={6} className="text-end">
        <img src={logo} width={80} height={20} alt="Logo" />
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={12} sm={6} className="text-center">
        <small className="">{endowmentType}</small>
      </CCol>
    </CRow>
  </>
)

VoucherHeader.propTypes = {
  endowmentType: PropTypes.string.isRequired,
}

export default VoucherHeader
