import { createSelector } from 'reselect'

export const denominationSelector = createSelector(
  (state) => state.cashCount.denominations,
  (denominations) => {
    let orderedDenominations = {}
    Object.keys(denominations).forEach((key) => {
      orderedDenominations[key] = denominations[key]
        .sort((a, b) => (a.value > b.value ? 1 : -1))
        .map((currency) => {
          return {
            ...currency,
            total: 0,
          }
        })
    })
    return orderedDenominations
  },
)
