import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { XStoreEndowmentContext } from './XStoreEndowmentContext'

export const XStoreEndowmentContextProvider = ({ children }) => {
  const [endowment, setEndowment] = useState({})

  const value = { endowment, setEndowment }

  return <XStoreEndowmentContext.Provider value={value}>{children}</XStoreEndowmentContext.Provider>
}

XStoreEndowmentContextProvider.propTypes = {
  children: PropTypes.node,
}

export default XStoreEndowmentContextProvider
