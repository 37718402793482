import React from 'react'
import { CRow, CCol } from '@coreui/react'
import logo from '@/assets/brand/logo-remittance.png'

const ReportHeader = () => (
  <>
    <CRow className="m-0  p-0">
      <CCol
        xs={8}
        sm={6}
        lg={8}
        md={8}
        className="text-end pt-3 m-0"
        style={{ paddingRight: '3.4rem' }}
      >
        <p className="fw-bolder p-0">Dotación de caja</p>
      </CCol>
      <CCol xs={4} sm={6} lg={4} md={4} className="text-end m-0 p-0">
        <img src={logo} width={150} height={50} alt="Logo" />
      </CCol>
    </CRow>
    <CRow className="m-0  p-0">
      <CCol xs={12} sm={6} lg={12} md={12} className="text-center m-0 p-0">
        <p className="fw-bolder p-0">Zü Cash</p>
      </CCol>
    </CRow>
    <CRow className="m-0  p-0">
      <CCol xs={12} sm={6} lg={12} md={12} className="text-center m-0 p-0">
        <p className="fw-bolder p-0">Dotajes realizados</p>
      </CCol>
    </CRow>
  </>
)

export default ReportHeader
