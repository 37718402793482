export const APPLICATION_ROOT = '/'

export const LOGIN = `${APPLICATION_ROOT}login`

export const DASHBOARD = `${APPLICATION_ROOT}dashboard`

export const CASHCOUNT_PANEL = `${APPLICATION_ROOT}cashcount`
export const CASHCOUNT_RECORD = `${CASHCOUNT_PANEL}/record`
export const CASHCOUNT_ACTIVITIES = `${CASHCOUNT_PANEL}/activities`
export const CASHCOUNT_DETAIL = `${CASHCOUNT_PANEL}/detail`
export const CASHCOUNT_REQUEST = `${CASHCOUNT_PANEL}/requests`

export const CASHCOUNT_XSTORE_ENDOWMENT_LIST = `${CASHCOUNT_PANEL}/endowment/xstore/list`
export const CASHCOUNT_XSTORE_ENDOWMENT_NEW = `${CASHCOUNT_PANEL}/endowment/xstore/new`
export const CASHCOUNT_ENDOWMENT = `${CASHCOUNT_REQUEST}/endowment/:requestId`
export const CASHCOUNT_ENDOWMENT_REPORT = `${CASHCOUNT_PANEL}/endowment/report`
export const CASHCOUNT_ENDOWMENT_SINGLE = `${CASHCOUNT_REQUEST}/endowment/`

export const REMITTANCES_PANEL = `${APPLICATION_ROOT}remittances`
export const REMITTANCE_PAYMENTS = `${REMITTANCES_PANEL}/payments`
export const REMITTANCE_CASHCOUNT = `${REMITTANCES_PANEL}/cashcount`

export const SERVICES_PAY_PANEL = `${APPLICATION_ROOT}services_pay`
export const SERVICES_PAY_PANEL_SERVICES = `${SERVICES_PAY_PANEL}/services`

export const ADMIN_PANEL = `${APPLICATION_ROOT}administration`
export const ADMIN_CASHIER = `${ADMIN_PANEL}/cashiers`
export const ADMIN_CASHIER_NEW = `${ADMIN_PANEL}/cashiers/new`
export const ADMIN_CASHIER_DETAIL = `${ADMIN_CASHIER}/:cashierId`

export const ADMIN_CASHIER_USER = `${ADMIN_PANEL}/cashierusers`
export const ADMIN_CASHIER_USER_NEW = `${ADMIN_PANEL}/cashierusers/new`
export const ADMIN_CASHIER_USER_DETAIL = `${ADMIN_CASHIER_USER}/:cashieruserId`
