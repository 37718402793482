import PropTypes from 'prop-types'
import React from 'react'
import { CButton, CSpinner } from '@coreui/react'
import CIcon from '@coreui/icons-react'

const LoadingButton = ({ disableButton, icon, text, ...rest }) => {
  return (
    <CButton {...rest}>
      {disableButton ? (
        <CSpinner className="me-3" component="span" size="sm" aria-hidden="true" />
      ) : icon ? (
        <CIcon icon={icon} height={20} customClassName="nav-icon me-2" />
      ) : null}
      {text}
    </CButton>
  )
}

LoadingButton.propTypes = {
  disableButton: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
}

export default React.memo(LoadingButton)
