import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { CPagination, CPaginationItem } from '@coreui/react'

const Paginator = ({ current, totalPages, rows, changePage, align }) => {
  const [range, setRange] = useState([])
  const [total, setTotal] = useState(1)

  useEffect(() => {
    setRange([...getRange()])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, totalPages])

  const getRange = () => {
    let ret = []
    let start = 0
    let items = 5
    const total = Math.ceil(totalPages / rows)
    setTotal(total)

    if (total < items) {
      items = total
    }

    if (current - 3 > 0) {
      start = current - 3
    }

    if (current + 3 > total) {
      start = total - items
    }

    for (let index = start; index < start + items; index++) {
      ret.push(index + 1)
    }
    return ret
  }

  const change = (pageNumber) => {
    changePage(pageNumber)
  }

  const skipPages = (pages) => {
    let toPage = current + pages

    if (toPage <= 0) {
      toPage = 1
    }

    if (toPage > total) {
      toPage = total
    }

    change(toPage)
  }

  return (
    <CPagination align={align} aria-label="Page navigation example">
      {current !== 1 ? (
        <CPaginationItem
          aria-label="Previous5"
          className="cursor-pointer"
          component={'a'}
          onClick={() => skipPages(-5)}
        >
          <span aria-hidden="true">&laquo;</span>
        </CPaginationItem>
      ) : null}
      {current !== 1 ? (
        <CPaginationItem
          aria-label="Previous1"
          className="cursor-pointer"
          component={'a'}
          onClick={() => skipPages(-1)}
        >
          <span aria-hidden="true">&lsaquo;</span>
        </CPaginationItem>
      ) : null}
      {range.map((page, index) => (
        <CPaginationItem
          className="cursor-pointer"
          active={current === page}
          key={index}
          component={'a'}
          onClick={() => change(page)}
        >
          {page}
        </CPaginationItem>
      ))}
      {current !== total ? (
        <CPaginationItem
          aria-label="Next1"
          className="cursor-pointer"
          component={'a'}
          onClick={() => skipPages(1)}
        >
          <span aria-hidden="true">&rsaquo;</span>
        </CPaginationItem>
      ) : null}
      {current !== total ? (
        <CPaginationItem
          aria-label="Next5"
          className="cursor-pointer"
          component={'a'}
          onClick={() => skipPages(5)}
        >
          <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
      ) : null}
    </CPagination>
  )
}

Paginator.defaultProps = {
  current: 1,
  totalPages: 1,
  align: 'start',
}

Paginator.propTypes = {
  current: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  align: PropTypes.string,
}

export default Paginator
