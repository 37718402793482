export const toNio = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('es-NI', {
    style: 'currency',
    currency: 'NIO',
    currencyDisplay: 'code',
  })
  return formatter.format(value)
}

export const toUSD = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  })
  return formatter.format(value)
}

export const toGTQ = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('en-GT', {
    style: 'currency',
    currency: 'GTQ',
    currencyDisplay: 'code',
  })
  return formatter.format(value)
}

export const toEUR = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
  })
  return formatter.format(value)
}

export const toGTQSimpleFormat = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('en-GT', {
    style: 'currency',
    currency: 'GTQ',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(value)
}

export const toNioSimpleFormat = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('es-NI', {
    style: 'currency',
    currency: 'NIO',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(value)
}

export const toUSDSimpleFormat = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(value)
}

export const toEURSimpleFormat = (value) => {
  if (!value) {
    return 0
  }

  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formatter.format(value)
}

export const toCurrencyFormat = (value) => {
  return new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
  }).format(value)
}
