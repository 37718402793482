import dev from './dev'
import prod from './prod'
import local from './local'
import qa from './qa'

const environments = {
  dev,
  prod,
  local,
  qa,
}

let globalEnv = {}

if (process.env.NODE_ENV !== 'development') {
  globalEnv = window._env_.REACT_APP_ENV
} else {
  globalEnv = process.env.REACT_APP_ENV
}

const config = environments[globalEnv.trim()]

const enviroment = {
  ...config,
}

export default enviroment
