import React from 'react'
import PropTypes from 'prop-types'
import { CCol, CRow, CFormSwitch, CTooltip } from '@coreui/react'

const CashierDenominationItem = ({ denomination, updateDenomination }) => {
  return (
    <CCol md={4} sm={3} className="m-0 justify-content-center">
      <CRow>
        <CCol lg={12} md={12} sm={12} className="p-0 justify-content-center">
          <CFormSwitch
            label={
              denomination.description ? (
                <CTooltip content={denomination.description ?? null} placement="top">
                  <small className="text-truncate">
                    {`${denomination.name}`}
                    <h6 className="d-inline mt-4">{`${denomination.description ? '¢' : ''}`}</h6>
                  </small>
                </CTooltip>
              ) : (
                <small className="text-truncate">{denomination.name}</small>
              )
            }
            size="lg"
            onChange={() =>
              updateDenomination('isAssigned', !denomination.isAssigned, denomination)
            }
            checked={denomination.isAssigned}
          />
        </CCol>
      </CRow>
    </CCol>
  )
}

CashierDenominationItem.propTypes = {
  denomination: PropTypes.object.isRequired,
  updateDenomination: PropTypes.func.isRequired,
}

export default CashierDenominationItem
