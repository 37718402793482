// import * as dayjs from 'dayjs'
// import * as utc from 'dayjs/plugin/utc'
// import * as localizedFormat from 'dayjs/plugin/localizedFormat'

// require('dayjs/locale/es')

// dayjs.locale('es')
// dayjs.extend(utc)
// dayjs.extend(localizedFormat)
import moment from 'moment'
import 'moment/locale/es'

const shortMonths = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_')
const months =
  'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_',
  )
const weeks = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_')
const shortWeeks = 'Dom_Lun_Mar_Mie_Jue_Vie_Sa'.split('_')

export const getDate = (dateString) => {
  let date = null
  moment.updateLocale('es', {
    months: months,
    weekdays: weeks,
    weekdaysShort: shortWeeks,
    monthsShort: shortMonths,
  })
  if (dateString) {
    date = moment(dateString)
  } else {
    date = moment(new Date())
  }

  return date
}

export const toMMDDYYYY = (date) => {
  return getDate(date).format('DD/MM/YYYY')
}

export const toMMDDYYYYHH = (date) => {
  return getDate(date).format('L HH:mm')
}

export const toHHMM = (date) => {
  return getDate(date).format('HH:mm')
}

export const toDetailDate = (date) => {
  return getDate(date).format('ddd, DD MMMM YYYY, h:mm a')
}
