import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'

const ReportDetail = ({ date, branch, cashier }) => (
  <>
    <CRow className="mt-5">
      <CCol xs={2} sm={6} md={2} className="text-start">
        <p className="fw-bolder">Fecha:</p>
      </CCol>
      <CCol xs={4} sm={6} md={4} className="text-start">
        <p className="fw-bolder">{date}</p>
      </CCol>
      <CCol xs={2} sm={6} md={1} className="text-start">
        <p className="fw-bolder">Cajero:</p>
      </CCol>
      <CCol xs={4} sm={6} md={5} className="text-start">
        <p className="fw-bolder">{cashier}</p>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={2} sm={6} md={2} className="text-start ">
        <p className="fw-bolder">Sucursal:</p>
      </CCol>
      <CCol xs={9} sm={6} md={10} className="text-start">
        <p className="fw-bolder">{branch}</p>
      </CCol>
    </CRow>
  </>
)

ReportDetail.propTypes = {
  date: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  cashier: PropTypes.string.isRequired,
}

export default ReportDetail
