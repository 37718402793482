import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@/components'
import { useNavigate } from 'react-router-dom'
import { isEmpty as isEmptyObject } from '@/utils/formatters'
import {
  CCol,
  CFormSelect,
  CRow,
  CButton,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMonitor, cilLan, cilPencil, cilBank, cilGlobeAlt } from '@coreui/icons'
import { ADMIN_CASHIER } from '@/router'
import { cashierTypes, cashierTypesList, countriesList } from '@/utils/constants'

const CashierForm = ({
  submit,
  defaultValue,
  disableButton,
  treasuryCashiers,
  companies,
  sellingPoints,
  cities,
  loadSellingPoints,
  loadCompanies,
  loadCities,
}) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      typeID: cashierTypes.Pago,
    },
  })
  const watchTypeID = watch('typeID', false)

  useEffect(() => {
    if (!isEmptyObject(defaultValue)) {
      reset({ ...defaultValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <CForm onSubmit={handleSubmit(submit)}>
      <CRow>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Nombre</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilPencil} />
            </CInputGroupText>
            <CFormInput
              placeholder="Nombre"
              autoComplete="cashierName"
              feedback={'Este campo es requerido'}
              invalid={errors.cashierName ? true : false}
              {...register('cashierName', { required: true })}
            />
          </CInputGroup>
        </CCol>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Tipo de cajero</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilMonitor} />
            </CInputGroupText>
            <CFormSelect
              {...register('typeID', {
                required: true,
              })}
            >
              <option defaultValue disabled>
                Tipo de cajero...
              </option>
              {cashierTypesList.map((type, index) => (
                <option key={index} value={type['value']}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Pais</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilGlobeAlt} />
            </CInputGroupText>
            <CFormSelect
              {...register('countryID', {
                required: true,
                onChange: async (env) => {
                  const response = await loadCompanies(env.target.value)
                  loadSellingPoints(response[0]['id'])
                  await loadCities(env.target.value)
                },
              })}
            >
              <option defaultValue disabled>
                Pais...
              </option>
              {countriesList.map((type, index) => (
                <option key={index} value={type['value']}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Ciudad</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilBank} />
            </CInputGroupText>
            <CFormSelect
              {...register('idCity', {
                required: true,
              })}
            >
              <option defaultValue disabled>
                Ciudad...
              </option>
              {cities.map((type, index) => (
                <option key={index} value={type['country_id']}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Compañía</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilBank} />
            </CInputGroupText>
            <CFormSelect
              {...register('companyID', {
                required: true,
                onChange: (env) => {
                  loadSellingPoints(env.target.value)
                },
              })}
            >
              <option defaultValue disabled>
                Compañía...
              </option>
              {companies.map((type, index) => (
                <option key={index} value={type['id']}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Sucursal</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilBank} />
            </CInputGroupText>
            <CFormSelect
              {...register('sellingPointID', {
                required: true,
              })}
            >
              <option defaultValue disabled>
                Sucursal...
              </option>
              {sellingPoints.map((type, index) => (
                <option key={index} value={type['id'].toString()}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        {Number(watchTypeID) === cashierTypes.Pago && (
          <CCol lg={6} md={6} sm={12} className="text-start mb-3">
            <label className="text-bold">Cajas de tresoreria</label>
            <CInputGroup>
              <CInputGroupText>
                <CIcon icon={cilLan} />
              </CInputGroupText>
              <CFormSelect
                {...register('treasuryCashiers', {})}
                multiple
                options={[
                  { label: 'Cajas de tresoreria', value: null, disabled: true },
                  ...treasuryCashiers.map((cashier) => ({
                    label: cashier['name'],
                    value: cashier['id'].toString(),
                  })),
                ]}
              ></CFormSelect>
            </CInputGroup>
          </CCol>
        )}
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <CFormCheck
            className="text-start mt-3"
            {...register('enableAutomaticAccounting', {})}
            label="Puede facturar"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={6} xs={6} className="text-start">
          <LoadingButton
            color="primary"
            type="submit"
            className="px-4 text-white"
            disableButton={disableButton}
            disabled={disableButton}
            text="Guardar"
          />
        </CCol>
        <CCol lg={6} xs={6} className="text-end">
          <CButton
            color="secondary"
            className="text-white"
            onClick={() => {
              navigate({ pathname: ADMIN_CASHIER })
            }}
          >
            Cancelar
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

CashierForm.propTypes = {
  submit: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  disableButton: PropTypes.bool.isRequired,
  treasuryCashiers: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  sellingPoints: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  loadSellingPoints: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  loadCities: PropTypes.func.isRequired,
}

export default CashierForm
