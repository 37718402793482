import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'

const TransactionAmount = ({
  amount,
  currencyCode,
  senderCharge,
  deliveryCharge,
  restCharges,
  taxes,
  total,
}) => (
  <>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Monto:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className=" fw-bolder">{amount}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Cargo por envío:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className=" fw-bolder">{senderCharge}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Cargo por entrega:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className=" fw-bolder">{deliveryCharge}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Otros cargos:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className=" fw-bolder">{restCharges}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Impuestos:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className=" fw-bolder">{taxes}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Total:</small>
      </CCol>
      <CCol xs={2} className="text-end">
        <small className="">{currencyCode}</small>
      </CCol>
      <CCol xs={3} className="text-end">
        <small className="fw-bolder">{total}</small>
      </CCol>
    </CRow>
  </>
)

TransactionAmount.propTypes = {
  amount: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  senderCharge: PropTypes.string.isRequired,
  deliveryCharge: PropTypes.string.isRequired,
  restCharges: PropTypes.string.isRequired,
  taxes: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
}

export default TransactionAmount
