import React from 'react'
import VoucherFooter from './Footer'
import VoucherHeader from './Header'
import VoucherDetail from './VoucherDetail'
import './index.scss'
import { CContainer } from '@coreui/react'

export class VoucherComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="voucher-content text-small">
          <CContainer className="ps-0">
            <VoucherHeader {...this.props} />
            <VoucherDetail {...this.props} />
            <VoucherFooter />
          </CContainer>
        </div>
      </div>
    )
  }
}

const EndowmentVoucherDocument = React.forwardRef((props, ref) => {
  return <VoucherComponent {...props} ref={ref} />
})

export default EndowmentVoucherDocument
