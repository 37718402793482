import React from 'react'
import { CRow, CCol } from '@coreui/react'

const ReceiptFooter = () => (
  <>
    <CRow className="mt-3">
      <CCol lg={12} className="text-center">
        <small className="">
          Estoy de acuerdo con los términos y condiciones del servicio que he leído y aceptado
        </small>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg={12} className="text-center mt-4">
        <small className="">-------------------------------------</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="">Firma del cliente</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg={12} className="text-center mt-4">
        <small className="">Para consultas quejas o sugerencias llamar al:</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="">+ (505) 2264 7484</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="">clientes@credex.com.ni</small>
      </CCol>
    </CRow>
  </>
)

export default ReceiptFooter
