import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilCalculator, cilMonitor, cilClipboard, cilMoney, cilChartLine } from '@coreui/icons'
import {
  DASHBOARD,
  CASHCOUNT_RECORD,
  CASHCOUNT_REQUEST,
  CASHCOUNT_XSTORE_ENDOWMENT_LIST,
  CASHCOUNT_ENDOWMENT_REPORT,
  ADMIN_CASHIER,
  ADMIN_CASHIER_USER,
} from '@/router'
import { CNavItem, CNavTitle } from '@coreui/react'

const getNavigation = (isOpenCashCount, user) => {
  let navigation = []

  // if (!abilities.can('CanView', 'ApplyPayments')) {
  //   navigation.push()
  // }

  if (user && user['roleId'] && Number(user['roleId']) === 31) {
    navigation = navigation.concat([
      {
        component: CNavTitle,
        name: 'Administración',
      },
      {
        component: CNavItem,
        name: 'Cajas',
        to: ADMIN_CASHIER,
        icon: <CIcon icon={cilMonitor} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: 'Asignación de usuarios',
        to: ADMIN_CASHIER_USER,
        icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
      },
    ])

    return navigation
  }

  navigation = [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: DASHBOARD,
      icon: <CIcon icon={cilMonitor} customClassName="nav-icon" />,
      badge: {
        color: !isOpenCashCount ? 'danger' : 'info',
        text: !isOpenCashCount ? 'CERRADA' : 'EN LINEA',
      },
    },
    {
      component: CNavTitle,
      name: 'Caja',
    },
    {
      component: CNavItem,
      name: 'Arqueo',
      to: CASHCOUNT_RECORD,
      icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      // disabled: !isOpenCashCount,
      name: 'Solicitudes',
      to: CASHCOUNT_REQUEST,
      icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      disabled: !isOpenCashCount,
      name: 'Dotajes Xstore',
      to: CASHCOUNT_XSTORE_ENDOWMENT_LIST,
      icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Reporte de dotajes',
      to: CASHCOUNT_ENDOWMENT_REPORT,
      icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
    },
  ]

  return navigation
}

export default getNavigation
