import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useService } from '@/hooks'
import PropTypes from 'prop-types'
import { CashierUserContext } from './CashierUserContext'
import { getDate } from '@/utils/formatters'

export const CashierUserContextProvider = ({ children }) => {
  const [cashierUser, setCashierUser] = useState({})
  const [isEditing, setEditing] = useState(false)
  const cashierService = useService('admin')
  const { cashieruserId } = useParams()

  useEffect(() => {
    if (cashieruserId) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashieruserId])

  const getData = async () => {
    const response = await cashierService.getCashierUser(cashieruserId)

    if (response.code || (response.response && response.response.status !== 200)) {
      return
    }
    response['dateStart'] = getDate(response['dateStart']).format('YYYY-MM-DD')
    response['dateEnd'] = getDate(response['dateEnd']).format('YYYY-MM-DD')
    //console.log(getDate(response['dateEnd']).format('YYYY-MM-DD'))
    setCashierUser({ ...response })
    setEditing(true)
  }

  const value = { cashierUser, setCashierUser, isEditing, cashieruserId }

  return <CashierUserContext.Provider value={value}>{children}</CashierUserContext.Provider>
}

CashierUserContextProvider.propTypes = {
  children: PropTypes.node,
}

export default CashierUserContextProvider
