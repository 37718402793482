import { combineReducers } from 'redux'
import uiReducer from './ui'
import cashCountReducer from './cashCount'

const allReducers = combineReducers({
  ui: uiReducer,
  cashCount: cashCountReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }

  return allReducers(state, action)
}

export default rootReducer
