export const cashierTypesList = [
  {
    name: 'Pago',
    value: 0,
  },
  {
    name: 'Tesoreria',
    value: 1,
  },
]

export const countriesList = [
  {
    name: 'Nicaragua',
    value: 1,
  },
  {
    name: 'Guatemala',
    value: 2,
  },
]
