import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'

const FormInfo = ({ id, sections }) => (
  <>
    <CRow className="mt-4">
      <CCol xs={6} sm={6} className="text-start ">
        <small className="fw-bolder">ID cliente</small>
      </CCol>
      <CCol xs={6} sm={6} className="text-start ">
        <small className="">{id}</small>
      </CCol>
    </CRow>
    {sections.map((section, index) => {
      return (
        <CRow className="mt-3" key={index}>
          <CCol xs={12} sm={6} className="text-start ">
            <small className="fw-bolder">{section['description']}</small>
          </CCol>
          <CCol xs={12} sm={6} className="text-start ">
            {section['keys'].map((field, indexField) => (
              <CRow key={indexField}>
                <CCol xs={6} sm={6} className="text-start pe-0">
                  <small className="">{field['name']}:</small>
                </CCol>
                <CCol
                  xs={5}
                  sm={6}
                  className="text-start d-flex ps-0"
                  style={{ alignItems: 'end', lineBreak: 'anywhere' }}
                >
                  <small className="">{field['value']}</small>
                </CCol>
              </CRow>
            ))}
          </CCol>
        </CRow>
      )
    })}
  </>
)

FormInfo.propTypes = {
  id: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
}

export default FormInfo
