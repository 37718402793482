export const isEmpty = (objectName) => {
  if (typeof objectName != 'object') return false

  return Object.keys(objectName).length === 0
}

export const toString = (value) => {
  if (value) {
    return value
  }
  return '-'
}

export const toNormalize = (value) => {
  if (value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
  return ''
}

export const inList = (value, list) => {
  return list.includes(value)
}

export const sortDenominations = (denominations) => {
  let orderedDenominations = {}
  Object.keys(denominations).forEach((key) => {
    orderedDenominations[key] = denominations[key]
      .sort((a, b) => (a.value > b.value ? 1 : -1))
      .map((currency) => {
        return {
          ...currency,
          total: 0,
        }
      })
  })

  return sortKeys(orderedDenominations)
}

export const sortKeys = (object) => {
  let sortedKeys = {}

  sortedKeys = Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
  return sortedKeys
}
