import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RemittanceContext } from './RemittanceContext'

export const RemittanceContextProvider = ({ children }) => {
  const [remittance, setRemittance] = useState({})

  const value = { remittance, setRemittance }

  return <RemittanceContext.Provider value={value}>{children}</RemittanceContext.Provider>
}

RemittanceContextProvider.propTypes = {
  children: PropTypes.node,
}

export default RemittanceContextProvider
