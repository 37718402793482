import React from 'react'
import PropTypes from 'prop-types'
import CashierDenominationList from './CashierDenominationList'
import { CCol, CRow } from '@coreui/react'
import CashierLimitItem from './CashierLimitItem'

const CashierDenominationSection = ({ denominations, limits, updateDenomination, updateLimit }) => {
  return (
    <CRow className="mt-0 pt-0">
      <CCol md={12} sm={12} lg={12} className="mt-0 pt-0">
        <CRow className="mt-0 pt-0">
          {Object.keys(denominations).map((key, index) => {
            if (Object.keys(denominations).length > 2) {
              return (
                <CCol md={4} sm={12} className="m-0 justify-content-center" key={index}>
                  <center>
                    <strong>{key}</strong>
                  </center>
                  <CRow className="p-3 justify-content-center">
                    <CashierDenominationList
                      denominations={denominations[key]}
                      updateDenomination={updateDenomination}
                    />
                    {limits[key] && (
                      <CCol
                        md={12}
                        sm={12}
                        className="justify-content-center text-center"
                        key={index}
                      >
                        <CashierLimitItem
                          currency={key}
                          limit={limits[key]}
                          updateLimit={updateLimit}
                        />
                      </CCol>
                    )}
                  </CRow>
                </CCol>
              )
            }
            return (
              <CCol md={6} sm={12} className="justify-content-center" key={index}>
                <center>
                  <strong>{key}</strong>
                </center>
                <CRow className="p-4 justify-content-center">
                  <CashierDenominationList
                    denominations={denominations[key]}
                    updateDenomination={updateDenomination}
                  />
                  {limits[key] && (
                    <CCol
                      md={12}
                      sm={12}
                      className="justify-content-center text-center"
                      key={index}
                    >
                      <CashierLimitItem
                        currency={key}
                        limit={limits[key]}
                        updateLimit={updateLimit}
                      />
                    </CCol>
                  )}
                </CRow>
              </CCol>
            )
          })}
        </CRow>
      </CCol>
    </CRow>
  )
}

CashierDenominationSection.propTypes = {
  denominations: PropTypes.object.isRequired,
  limits: PropTypes.object.isRequired,
  updateDenomination: PropTypes.func.isRequired,
  updateLimit: PropTypes.func.isRequired,
}

export default CashierDenominationSection
