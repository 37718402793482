import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useService } from '@/hooks'
import PropTypes from 'prop-types'
import { CashierContext } from './CashierContext'

export const CashierContextProvider = ({ children }) => {
  const [cashier, setCashier] = useState({})
  const [isEditing, setEditing] = useState(false)
  const cashierService = useService('admin')
  const { cashierId } = useParams()

  useEffect(() => {
    if (cashierId) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierId])

  const getData = async () => {
    const response = await cashierService.getCashier(cashierId)

    if (response.code || (response.response && response.response.status !== 200)) {
      return
    }

    let treasuryCashiers = response['treasuryCashiers'].map((cashierId) => cashierId.toString())

    var cashierInfo = {
      ...response,
      sellingPointID: response['sellingPointID'].toString(),
      treasuryCashiers: [...treasuryCashiers],
    }
    setCashier(cashierInfo)
    setEditing(true)
  }

  const value = { cashier, setCashier, isEditing, cashierId }

  return <CashierContext.Provider value={value}>{children}</CashierContext.Provider>
}

CashierContextProvider.propTypes = {
  children: PropTypes.node,
}

export default CashierContextProvider
