import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import CMaskInput from './CMaskInput'
import { CFormInput, CFormSelect, CFormCheck, CCol, CRow, CContainer } from '@coreui/react'

// eslint-disable-next-line react/display-name
const DynamicFieldControl = forwardRef((props, ref) => {
  const { control } = props

  const getNumberLength = () => {
    let mask = ''
    let { maxLength } = props

    for (let x = 0; x < maxLength; x++) {
      mask = `${mask}0`
    }
    return mask
  }
  return (
    <>
      {control === 'TEXT' && <CFormInput ref={ref} {...props} />}
      {control === 'DATE' && (
        <>
          <CFormInput className="text-medium-emphasis m-0" ref={ref} type="date" {...props} />
        </>
      )}
      {control === 'DROPDOWN' && <CFormSelect ref={ref} {...props} />}
      {control === 'CHECKBOX' && (
        <>
          {props['options'].map((option, index) => {
            let newProps = { ...props }
            newProps['defaultValue'] = undefined
            return (
              <CContainer key={index}>
                <CRow className="p-0 text-start">
                  <CCol lg={2} md={1} sm={1} className="p-0 text-start dynamic-form-check-input">
                    <CFormCheck
                      className="text-start justify-content-start p-0 mt-1 dynamic-form-check-input"
                      type="radio"
                      reverse
                      ref={ref}
                      value={option['value']}
                      {...newProps}
                      label={option['value']}
                    />
                  </CCol>
                </CRow>
              </CContainer>
            )
          })}
        </>
      )}
      {control === 'NUMBER' && (
        <CMaskInput
          ref={ref}
          maskOptions={{
            mask: getNumberLength(),
          }}
          {...props}
        />
      )}
    </>
  )
})

DynamicFieldControl.propTypes = {
  control: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  maxLength: PropTypes.number,
}

export default DynamicFieldControl
