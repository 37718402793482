import React from 'react'
import PropTypes from 'prop-types'
import DenominationItem from './DenominationItem'

const DenominationList = ({ denominations, currencyFormat, updateDenomination, readonly }) => {
  return (
    <>
      {denominations.map((denomination, index) => (
        <DenominationItem
          key={index}
          denomination={denomination}
          currencyFormat={currencyFormat}
          updateDenomination={updateDenomination}
          readonly={readonly}
        />
      ))}
    </>
  )
}

DenominationList.propTypes = {
  denominations: PropTypes.array.isRequired,
  currencyFormat: PropTypes.func.isRequired,
  updateDenomination: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}

export default DenominationList
