import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CashCountContext } from './CashCountContext'
import { useNotification, useService } from '@/hooks'
import { getDate, toCurrencyFormat, toNormalize } from '@/utils/formatters'
import { cashierNotificationType, cashierTypes, transactionType } from '@/utils/constants'

export const CashCountContextProvider = ({ children }) => {
  const {
    enableAutomaticAccounting,
    totals,
    totalsTransaction,
    limits,
    transactionList,
    lastOpenDate,
    cashierId,
    cashierType,
  } = useSelector((state) => state.cashCount)
  const cashCountService = useService('cashCount')
  const notification = useNotification()

  const getTransactionsByCurrency = (currency) => {
    let incomeAmount = 0
    let expenseAmount = 0
    let endowmentAmount = 0
    let transactions = transactionList[currency]
    if (!transactions) {
      return [incomeAmount, expenseAmount, endowmentAmount]
    }

    const incomesList = transactions.filter((transaction) => transaction.type === 6)
    incomeAmount = incomesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0,
    )
    const expenseList = transactions.filter((transaction) => transaction.sign < 0)
    expenseAmount = expenseList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0,
    )

    const endowmentList = transactions.filter(
      (transaction) => transaction.type === transactionType.Dotaje,
    )
    endowmentAmount = endowmentList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0,
    )

    return [incomeAmount, expenseAmount, endowmentAmount]
  }

  const getInitialAmountByCurrency = (currency) => {
    if (!totals[currency]) {
      return '0'
    }
    let initialAmount = totals[currency]
    // eslint-disable-next-line no-unused-vars
    let [incomeAmount, _, endowmentAmount] = getTransactionsByCurrency(currency)
    const withdrawalAmount = getTreasuryWithdrawal(currency)
    const amount = initialAmount + withdrawalAmount - endowmentAmount
    return amount < 0 ? Math.abs(amount).toString() : amount.toString()
  }

  const getTreasuryWithdrawal = (currency) => {
    let withdrawalAmount = 0

    let transactions = transactionList[currency]
    if (!transactions) {
      return withdrawalAmount
    }
    const expenseList = transactions.filter((transaction) => [22].includes(transaction.type))
    withdrawalAmount = expenseList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0,
    )
    return withdrawalAmount
  }

  const getPerformanceByCurrency = (currency) => {
    if (!totals[currency]) {
      return '0'
    }
    let initialAmount = getInitialAmountByCurrency(currency)
    let [incomeAmount, expenseAmount, endowmentAmount] = getTransactionsByCurrency(currency)
    const amount = Number(initialAmount) + incomeAmount + endowmentAmount - expenseAmount
    return amount < 0 ? Math.abs(amount).toString() : amount.toString()
  }

  const fundsByCurrency = (currency) => {
    const initialAmount = getInitialAmountByCurrency(currency)
    if (!initialAmount) {
      return 0
    }
    let totalTransactionCurrency = totalsTransaction[currency] ? totalsTransaction[currency] : 0
    const amount = Number(initialAmount) + totalTransactionCurrency
    return amount
  }

  const calculateFundsByTransaccion = (currency, transactionAmount) => {
    const initialAmount = totals[currency]
    const limit = limits[currency]
    if (!initialAmount || !limit) {
      return true
    }

    const funds = fundsByCurrency(currency)

    const amount = funds - transactionAmount
    return amount
  }

  const checkLimitFunds = (currency, transactionAmount) => {
    const initialAmount = totals[currency]
    const limit = limits[currency]
    if (!initialAmount || !limit) {
      return true
    }

    const funds = fundsByCurrency(currency)

    const amount = funds - transactionAmount
    return amount <= limit['min']
  }

  const getFundMessage = (currency, transactionAmount) => {
    // const notEnoughFunds = checkLimitFunds(currency, transactionAmount)
    const limit = limits[currency]
    if (!limit) {
      return 'No se ha configurado un limite para esta moneda.'
    }
    const funds = fundsByCurrency(currency)

    const amount = funds - transactionAmount

    // eslint-disable-next-line prettier/prettier
    let minAmount = limit['min'] * 1.2

    // if (notEnoughFunds && amount <= limit['min'])
    //  return 'Has alcanzado el limit de fondos, por favor realiza un dotaje.'
    if (amount <= minAmount)
      return 'Has alcanzado el limite de fondos, por favor realiza un dotaje.'
    return ''
  }

  const addTransaction = async (payload) => {
    if (!payload['currency']) {
      notification.error('Atencion!', 'Código de moneda requerido para crear transacción')
      return false
    }

    if (!payload['amount']) {
      notification.error('Atencion!', 'Cantidad es requerida para crear transacción')
      return false
    }

    if (!payload['operation']) {
      notification.error('Atencion!', 'Operacion es requerida para crear transacción')
      return false
    }

    if (payload['operation'] < 0) {
      // const notEnoughFunds = checkLimitFunds(payload['currency'], payload['amount'])
      const message = getFundMessage(payload['currency'], payload['amount'])

      // if (notEnoughFunds && message !== '') {
      //   notification.error('Atencion!', message)
      //   // return false
      // }

      if (message !== '') {
        addCashierNotification({
          emittedBy: cashierId,
          emittedTo: cashierId,
          type: cashierNotificationType.MinimoEnCaja,
          description: 'Has alcanzado el limite de fondos, por favor realiza un dotaje.',
        })
        notification.warning('Atencion!', message)
      }
    }

    const response = await cashCountService.addPOSTransaction(payload)
    if (Number.isInteger(response)) {
      return true
    }
    return false
  }

  const addCashierNotification = async (payload) => {
    const response = await cashCountService.addCashierNotifications(payload)
    if (response.code || (response.response && response.response.status !== 200)) {
      return true
    }
    return false
  }

  const isOperational = () => {
    const lastOpen = getDate(lastOpenDate)

    return getDate().isAfter(lastOpen, 'day')
  }

  const validateLimitsByCurrency = (currency, amount, isXStore = false) => {
    if (limits[currency]) {
      const { min, max } = limits[currency]
      if (Number(amount) < Number(min) || Number(amount) > Number(max)) {
        throw new Error(
          `${currency} debe estar en el rango de ${toCurrencyFormat(min)} y ${toCurrencyFormat(
            max,
          )}`,
        )
      }

      if (isXStore || cashierType === cashierTypes.Pago) {
        const funds = fundsByCurrency(currency)
        if (Number(funds) + Number(amount) > Number(max)) {
          throw new Error(`${currency} no debe rebasar el máximo: ${toCurrencyFormat(max)}`)
        }
      }
    }
  }

  const validateLimits = (amountByCurrency, isXStore = false) => {
    try {
      Object.keys(amountByCurrency).forEach((key) => {
        const amount = amountByCurrency[key]
        validateLimitsByCurrency(key, amount, isXStore)
      })
    } catch (e) {
      notification.error('Atención!', e.message)
      return false
    }
    return true
  }

  const validateRemittanceCustomerName = (remittance, customerFound) => {
    let remittanceShortCustomerName = joinString(
      remittance['receiver']['first_name'],
      remittance['receiver']['last_name'],
    )

    let customerFoundShorName = joinString(customerFound['first_name'], customerFound['last_name'])

    let normalizeRemittenceShortName = toNormalize(remittanceShortCustomerName.toLocaleLowerCase())
    let normalizeCustomerFoundShortName = toNormalize(
      customerFoundShorName.trim().toLocaleLowerCase(),
    )

    if (normalizeRemittenceShortName === normalizeCustomerFoundShortName) {
      return true
    }

    let remittanceCustomer = joinString(
      remittance['receiver']['first_name'],
      remittance['receiver']['middle_name'],
      remittance['receiver']['last_name'],
      remittance['receiver']['last_name2'],
    )

    let customerNameFound = joinString(
      customerFound['first_name'],
      customerFound['middle_name'],
      customerFound['last_name'],
      customerFound['last_name2'],
    )

    let normalizeRemittenceCustomer = toNormalize(remittanceCustomer.toLocaleLowerCase())
    let normalizeCustomerFound = toNormalize(customerNameFound.trim().toLocaleLowerCase())

    if (normalizeRemittenceCustomer === normalizeCustomerFound) {
      return true
    }

    return false
  }

  const joinString = (str1, str2, str3, str4) => {
    const stringList = [str1, str2, str3, str4]
    let stringOutput = ''

    stringList.forEach((stringItem) => {
      if (stringItem != null && stringItem !== undefined && stringItem !== '') {
        stringOutput += ' '
        stringOutput += stringItem.trim()
      }
    })

    return stringOutput.trim()
  }

  const value = {
    fundsByCurrency,
    enableAutomaticAccounting,
    isOperational,
    addTransaction,
    checkLimitFunds,
    getPerformanceByCurrency,
    getInitialAmountByCurrency,
    getTransactionsByCurrency,
    validateLimitsByCurrency,
    validateLimits,
    addCashierNotification,
    calculateFundsByTransaccion,
    validateRemittanceCustomerName,
  }

  return <CashCountContext.Provider value={value}>{children}</CashCountContext.Provider>
}

CashCountContextProvider.propTypes = {
  children: PropTypes.node,
}

export default CashCountContextProvider
