import React from 'react'
import { CFooter } from '@coreui/react'
import { availableEnvironments } from '@/utils/constants'

const AppFooter = () => {
  let globalEnv
  if (process.env.NODE_ENV !== 'development') {
    globalEnv = window._env_.REACT_APP_ENV
  } else {
    globalEnv = process.env.REACT_APP_ENV
  }

  return (
    <CFooter>
      <div>
        Copyright
        <span className="ms-1">
          &copy; 2023 <strong>FID S.A</strong>
        </span>
      </div>
      <div className="ms-auto">
        <span>
          <span className="text-info">{availableEnvironments[globalEnv]}</span>
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
