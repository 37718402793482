import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CMaskInput } from '@/components'
import { CCol, CRow, CInputGroup, CFormSwitch, CTooltip } from '@coreui/react'

const DenominationItem = ({ denomination, currencyFormat, updateDenomination, readonly }) => {
  const [deno, setDeno] = useState({ ...denomination })

  useEffect(() => {
    setDeno({ ...denomination })
  }, [denomination])
  return (
    <CCol md={4} sm={3} className="m-0 justify-content-center">
      <CRow>
        <CCol lg={12} md={12} sm={12} className="p-0 justify-content-center">
          <CFormSwitch
            label={
              deno.description ? (
                <CTooltip content={deno.description ?? null} placement="top">
                  <small className="text-truncate">
                    {`${deno.name}`}
                    <h6 className="d-inline mt-4">{`${deno.description ? '¢' : ''}`}</h6>
                  </small>
                </CTooltip>
              ) : (
                <small className="text-truncate">{`${currencyFormat(deno.name)}`}</small>
              )
            }
            disabled={readonly}
            size="lg"
            onChange={() => updateDenomination('isChecked', !deno.isChecked, deno)}
            checked={deno.isChecked}
          />
        </CCol>
        <CCol lg={8} md={8} sm={8} className="m-0 p-0 justify-content-center">
          <CInputGroup className="mb-3">
            <CMaskInput
              size="sm"
              maskOptions={{
                mask: '0000',
              }}
              onChange={(env, _maskRef) => {
                updateDenomination('total', env.target.value, deno)
                _maskRef.updateValue()
              }}
              readOnly={readonly}
              disabled={!deno.isChecked}
              value={deno.total}
            />
          </CInputGroup>
        </CCol>
      </CRow>
    </CCol>
  )
}

DenominationItem.propTypes = {
  denomination: PropTypes.object.isRequired,
  currencyFormat: PropTypes.func.isRequired,
  updateDenomination: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}

export default DenominationItem
