import { useNotification } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import services from './services'

export const useService = (keys) => {
  const notification = useNotification()
  const navigator = useNavigate()

  const getInstance = (key) => {
    try {
      let instance = new services[key](navigator, notification)
      return instance
    } catch (e) {
      return null
    }
  }

  const getServices = () => {
    if (Array.isArray(keys)) {
      let instances = []
      keys.forEach((key) => {
        instances.push(getInstance(key))
      })
      return instances
    }

    if (typeof keys == 'string') {
      return getInstance(keys)
    }
  }

  const instances = getServices()

  return instances
}
