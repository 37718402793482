const initialState = {
  notifications: [],
  sidebarShow: true,
  sidebarUnfoldable: false,
}

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      }
    case 'DELETE_TOAST':
      const updatedNotifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      )
      return {
        ...state,
        notifications: updatedNotifications,
      }
    case 'SET_DRAWER':
      return { ...state, sidebarShow: action.payload }
    case 'SET_UNFOLDABLE':
      return { ...state, sidebarUnfoldable: action.payload }
    default:
      return state
  }
}

export default uiReducer
