import React from 'react'
import { CRow, CCol } from '@coreui/react'
import logo from '@/assets/brand/logo-remittance.png'

const ReportHeader = () => (
  <>
    <CRow>
      <CCol xs={12} sm={6} className="text-center">
        <small className="fw-bolder">Sistema de pago de Remesas</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} sm={6} className="text-end pe-2">
        <small className="fw-bolder">Zü Cash</small>
      </CCol>
      <CCol xs={5} sm={6} className="text-end">
        <img src={logo} width={70} height={20} alt="Logo" />
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={12} sm={6} className="text-center">
        <small className="fw-bolder">Remesas pagadas</small>
      </CCol>
    </CRow>
  </>
)

export default ReportHeader
