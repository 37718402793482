const initialState = {
  forceLoadInitialCash: false,
  isOpenCashCount: false,
  isSupervisor: false,
  enableAutomaticAccounting: false,
  denominations: {},
  cashierId: null,
  supervisorId: null,
  sellingPointId: null,
  userId: null,
  cashierType: null,
  cashierName: '',
  cashierUsername: '',
  cashierSellingPointName: '',
  lastOpenDate: null,
  city: {},
  totals: {},
  totalsTransaction: {},
  limits: {},
  transactionList: {},
}

const cashCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_CASHCOUNT':
      return {
        ...state,
        denominations: { ...action.payload },
        isOpenCashCount: true,
      }
    case 'CLOSE_CASHCOUNT':
      return {
        ...state,
        denominations: { ...action.payload },
        isOpenCashCount: false,
      }
    case 'UPDATE_ITEM_DENOMINATION':
      const { field, payload, value } = action.payload
      const newState = state.denominations.map((denomination) =>
        denomination.id === payload.id ? { ...denomination, [field]: value } : denomination,
      )
      return { ...state, denominations: [...newState] }
    case 'UPDATE_DENOMINATIONS':
      return { ...state, denominations: { ...action.payload } }
    case 'INITIAL_CASHCOUNT':
      const { denominations } = action.payload
      return {
        ...state,
        userId: action.payload['userId'],
        cashierId: action.payload['cashierId'],
        sellingPointId: action.payload['sellingPointId'],
        supervisorId: action.payload['supervisorId'],
        cashierType: action.payload['cashierType'],
        cashierName: action.payload['cashierName'],
        cashierUsername: action.payload['cashierUsername'],
        cashierSellingPointName: action.payload['cashierSellingPointName'],
        enableAutomaticAccounting: action.payload['enableAutomaticAccounting'],
        lastOpenDate: action.payload['lastOpenDate'],
        city: { ...action.payload['city'] },
        totals: { ...action.payload['totals'] },
        totalsTransaction: { ...action.payload['totalsTransaction'] },
        limits: { ...action.payload['limits'] },
        transactionList: { ...action.payload['transactionList'] },
        denominations: { ...denominations },
      }
    case 'FORCE_INITIAL_CASHCOUNT':
      return {
        ...state,
        forceLoadInitialCash: action.payload,
      }
    default:
      return state
  }
}

export default cashCountReducer
