import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SHA256, enc } from 'crypto-js'
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CInputGroup,
  CFormInput,
} from '@coreui/react'
import { LoadingButton } from '@/components'
import { useService } from '@/hooks'

const PasswordModalConfirm = ({ title, visible, confirm, cancel }) => {
  const cashCountService = useService('cashCount')
  const [disableButton, setDisableButton] = useState(false)
  const [password, setPassword] = useState('')
  const [validate, setValidate] = useState(false)
  const [invalid, setInvalid] = useState(false)

  const validateAccess = async (event) => {
    setDisableButton(true)
    if (password === '') {
      setValidate(false)
      setInvalid(true)
      setDisableButton(false)
      return
    }

    let payload = {
      password: SHA256(password).toString(enc.Hex),
    }
    const response = await cashCountService.verifySupervisorAccess(payload)

    if (typeof response === 'boolean' && response === true) {
      setValidate(true)
      confirm()
    } else {
      setInvalid(true)
    }
    setDisableButton(false)
  }

  const cancelable = () => {
    if (!validate) {
      cancel()
      return
    }
  }

  return (
    <>
      <CModal alignment="center" backdrop="static" visible={visible} onClose={cancelable}>
        <CModalHeader>
          <CModalTitle>{title ? title : 'Arqueo'}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol lg={12} md={12} sm={12}>
              <p>Ingrese su contraseña para proceder</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg={12} md={12} sm={12}>
              <CInputGroup className="mb-3">
                <CFormInput
                  placeholder="Contraseña de acceso"
                  type="password"
                  autoComplete="current-password"
                  feedbackInvalid="Contraseña incorrecta"
                  invalid={invalid}
                  required
                  onInput={(event) => {
                    setInvalid(false)
                    setPassword(event.target.value)
                  }}
                />
              </CInputGroup>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" className="text-white" onClick={cancel}>
            Cancelar
          </CButton>
          <LoadingButton
            color="primary"
            disabled={disableButton}
            className="px-4 text-white"
            type="submit"
            disableButton={disableButton}
            onClick={validateAccess}
            text={disableButton ? 'Validando..' : 'Validar'}
          />
        </CModalFooter>
      </CModal>
    </>
  )
}

PasswordModalConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default PasswordModalConfirm
