import React from 'react'

import {
  CASHCOUNT_RECORD,
  CASHCOUNT_DETAIL,
  CASHCOUNT_ENDOWMENT,
  CASHCOUNT_REQUEST,
  CASHCOUNT_ENDOWMENT_SINGLE,
  CASHCOUNT_ENDOWMENT_REPORT,
  CASHCOUNT_XSTORE_ENDOWMENT_LIST,
  CASHCOUNT_XSTORE_ENDOWMENT_NEW,
} from './routes'

const CashCountRecord = React.lazy(() =>
  import('@/features/cashCount/cashCountRecord/CashCountRecord'),
)
const CashCountDetail = React.lazy(() =>
  import('@/features/cashCount/cashCountDetail/CashCountDetail'),
)
const CashCountRequest = React.lazy(() =>
  import('@/features/cashCount/cashCountRequest/CashCountRequest'),
)
const Endowment = React.lazy(() => import('@/features/cashCount/endowment/Endowment'))
const XStoreEndowment = React.lazy(() =>
  import('@/features/cashCount/xStoreEndowment/XStoreEndowment'),
)
const XStoreEndowmentList = React.lazy(() =>
  import('@/features/cashCount/xStoreEndowmentList/XStoreEndowmentList'),
)
const EndowmentReport = React.lazy(() =>
  import('@/features/cashCount/endowmentReport/EndowmentReport'),
)

const cashCountRouter = [
  { path: CASHCOUNT_RECORD, name: 'Arqueo de Caja', element: CashCountRecord },
  { path: CASHCOUNT_DETAIL, name: 'Desglose', element: CashCountDetail },
  { path: CASHCOUNT_REQUEST, name: 'Solicitudes', element: CashCountRequest },
  { path: CASHCOUNT_ENDOWMENT, name: 'Edicion de dotaje', element: Endowment },
  { path: CASHCOUNT_ENDOWMENT_SINGLE, name: 'Solicitud de dotaje', element: Endowment },
  { path: CASHCOUNT_XSTORE_ENDOWMENT_LIST, name: 'Dotajes Xstore', element: XStoreEndowmentList },
  { path: CASHCOUNT_XSTORE_ENDOWMENT_NEW, name: 'Nuevo Dotaje Xstore', element: XStoreEndowment },
  { path: CASHCOUNT_ENDOWMENT_REPORT, name: 'Reporte de dotajes', element: EndowmentReport },
]

export default cashCountRouter
