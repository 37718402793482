import React from 'react'
import PropTypes from 'prop-types'
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'

const ModalConfirm = ({ visible, setVisible, confirm, title, bodyString }) => {
  return (
    <>
      <CModal
        alignment="center"
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{bodyString}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" className="text-white" onClick={() => setVisible(false)}>
            No
          </CButton>
          <CButton color="primary" className="text-white" onClick={confirm}>
            Sí
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

ModalConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bodyString: PropTypes.string.isRequired,
}

export default ModalConfirm
