import React from 'react'
import PropTypes from 'prop-types'
import { CTable } from '@coreui/react'

const ReportAmount = ({ data }) => {
  return (
    <>
      <CTable
        bordered
        columns={data.columns}
        items={data.items}
        footer={data.footer}
        className="mt-3"
      />
    </>
  )
}

ReportAmount.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ReportAmount
