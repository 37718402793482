import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'
import { useSelector } from 'react-redux'
import { cashierTypes } from '@/utils/constants'

const VoucherDetail = ({
  sequence,
  date,
  branch,
  supervisor,
  cashCountUser,
  cashier,
  currency,
  amount,
}) => {
  const { cashierType } = useSelector((state) => state.cashCount)
  return (
    <>
      <CRow className="mt-3">
        {sequence && (
          <>
            <CCol xs={3} sm={6} className="text-start">
              <small className="">Secuencia:</small>
            </CCol>
            <CCol xs={4} sm={6} className="text-start">
              <small className="fw-bolder">{sequence}</small>
            </CCol>
          </>
        )}

        <CCol xs={2} sm={6} className="text-start">
          <small className="">Caja:</small>
        </CCol>
        <CCol xs={3} sm={6} className="text-start">
          <small className="fw-bolder">{cashier}</small>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={3} sm={6} className="text-start ">
          <small className="">Fecha:</small>
        </CCol>
        <CCol xs={9} sm={6} className="text-start  p-0">
          <small className="fw-bolder">{date}</small>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={3} sm={6} className="text-start">
          <small className="">Sucursal:</small>
        </CCol>
        <CCol xs={9} sm={6} className="text-start p-0">
          <small className="fw-bolder">{branch}</small>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={3} sm={6} className="text-start">
          <small className="">Cajero:</small>
        </CCol>
        <CCol xs={9} sm={6} className="text-start p-0">
          <small className="fw-bolder">{cashCountUser}</small>
        </CCol>
      </CRow>
      {cashierType === cashierTypes.Pago && (
        <CRow>
          <CCol xs={4} sm={6} className="text-start">
            <small className="">Supervisor:</small>
          </CCol>
          <CCol xs={8} sm={6} className="text-start p-0">
            <small className="fw-bolder">{supervisor}</small>
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol xs={2} sm={6} className="text-start ">
          <small className="">Total:</small>
        </CCol>
        <CCol xs={4} sm={6} className="text-center p-0">
          <small className="fw-bolder">{amount}</small>
        </CCol>
        <CCol xs={2} sm={6} className="text-start p-0">
          <small className="">Moneda:</small>
        </CCol>
        <CCol xs={1} sm={6} className="text-start p-0">
          <small className="fw-bolder">{currency}</small>
        </CCol>
      </CRow>
    </>
  )
}

VoucherDetail.propTypes = {
  sequence: PropTypes.number,
  date: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  endowmentType: PropTypes.string.isRequired,
  supervisor: PropTypes.string,
  cashCountUser: PropTypes.string.isRequired,
  cashier: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
}

export default VoucherDetail
