import React from 'react'
import VoucherFooter from './Footer'
import VoucherHeader from './Header'
import FormInfo from './FormInfo'
import './index.scss'
import { CContainer } from '@coreui/react'

export class VoucherCustomerComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="main-content text-small">
          <CContainer className="ps-0">
            <VoucherHeader />
            <FormInfo {...this.props} />
            {/*<TransactionAmount {...this.props} /> */}
            <VoucherFooter />
          </CContainer>
        </div>
      </div>
    )
  }
}

const VoucherCustomerDocument = React.forwardRef((props, ref) => {
  return <VoucherCustomerComponent {...props} ref={ref} />
})

export default VoucherCustomerDocument
