import React from 'react'
import PropTypes from 'prop-types'
import { CToast, CToastHeader, CToastBody, CToastClose } from '@coreui/react'

const WarningNotification = ({ tittle, message }) => {
  return (
    <CToast
      autohide={true}
      color="warning"
      className="align-items-center"
      title="WarningNotification"
    >
      <CToastHeader className="text-white">
        <strong className="me-auto">{tittle}</strong>
        <CToastClose className="me-2 m-auto" white />
      </CToastHeader>
      <div className="d-flex">
        <CToastBody className="text-white">{message}</CToastBody>
      </div>
    </CToast>
  )
}

WarningNotification.propTypes = {
  tittle: PropTypes.string,
  message: PropTypes.string,
}

export default WarningNotification
