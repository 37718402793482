import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler, CImage } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useAcl, useAuthUser } from '@/hooks'
import CIcon from '@coreui/icons-react'
import { cilMoney, cilChartLine } from '@coreui/icons'
import { REMITTANCE_PAYMENTS, REMITTANCE_CASHCOUNT } from '@/router'
import { CNavItem, CNavTitle } from '@coreui/react'
import img from '@/assets/brand/zu-favicon.png'

// sidebar nav config
import getNavigation from '../_nav'
import { cashierTypes } from '@/utils/constants'

const AppSidebar = () => {
  const [navigation, setNavigation] = useState([])
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const { abilities } = useAcl()
  const { user } = useAuthUser()
  const unfoldable = useSelector((state) => state.ui.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.ui.sidebarShow)
  const { isOpenCashCount, cashierType } = useSelector((state) => state.cashCount)

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierType, isOpenCashCount])

  const getItems = () => {
    let navigation = getNavigation(isOpenCashCount, user)

    if (cashierType === cashierTypes.Pago) {
      navigation = navigation.concat([
        {
          component: CNavTitle,
          name: 'Remesas',
        },
        {
          component: CNavItem,
          disabled: !isOpenCashCount,
          name: 'Realizar pago',
          to: REMITTANCE_PAYMENTS,
          icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Remesas pagadas',
          to: REMITTANCE_CASHCOUNT,
          icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
        },
      ])
    }
    setNavigation(navigation)
  }
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'SET_DRAWER', payload: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex m-0 p-0" to="/">
        <div className="sidebar-brand-full w-100">
          <CImage className="ms-2 me-2" align="start" src={img} width={70} height={60} />
          <h2 className="mt-2">Cash</h2>
        </div>
        <CImage className="sidebar-brand-narrow" src={img} width={50} height={50} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'SET_UNFOLDABLE', payload: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
