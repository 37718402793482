import {
  toNio,
  toUSD,
  toGTQ,
  toEUR,
  toUSDSimpleFormat,
  toNioSimpleFormat,
  toGTQSimpleFormat,
  toEURSimpleFormat,
} from '@/utils/formatters/currencyFormat'

export * from './remittance'
export * from './customer'
export * from './environment'
export * from './cashCount'
export * from './admin'

export const userStorageKey = '__loggedInUser__'
export const denominationsStorageKey = '__denominations__'
export const permissionStorageKey = '__aclService__'
export const adminPassword = '1234'
export const emailRegExp = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/

export const currencyNameMapper = {
  USD: 'Dólar',
  NIO: 'Córdoba',
  GTQ: 'Quetzal',
}

export const currencyMethodMapper = {
  USD: toUSD,
  NIO: toNio,
  GTQ: toGTQ,
  EUR: toEUR,
}

export const currencySimpleMapper = {
  USD: toUSDSimpleFormat,
  NIO: toNioSimpleFormat,
  GTQ: toGTQSimpleFormat,
  EUR: toEURSimpleFormat,
}

export const permissionEnum = {
  0: 'CanView',
  1: 'CanAdd',
  2: 'CanChange',
  3: 'CanDelete',
}
