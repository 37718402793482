import { isEmpty } from '@/utils/formatters'
import axios from 'axios'
class Service {
  baseUrl = ''

  async post(url, data, config) {
    return await axios.post(url, data, config)
  }

  async get(url, params) {
    let config = {
      ...params,
    }
    if (params && !isEmpty(params)) {
      config = {
        ...config,
        ...params,
      }
    }
    return await axios.get(url, config)
  }

  async getResource(url, params) {
    return await this.get(`${this.baseUrl}${url}`, { params: params })
  }

  async put(url, data) {
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    return await axios.put(url, data, config)
  }
}

export default class BaseService extends Service {
  baseUrl = ''

  async createEntity(data) {
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    return await this.post(this.baseUrl, data, config)
  }

  async getEntity() {
    return await this.get(this.baseUrl)
  }

  async updateEntity(data) {
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    return await this.put(this.baseUrl, data, config)
  }

  async deleteEntity() {
    return await this.delete(this.baseUrl)
  }
}
