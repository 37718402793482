import React from 'react'
import PropTypes from 'prop-types'
import { CToast, CToastBody, CToastClose } from '@coreui/react'

const notificationTypes = {
  success: {
    autohide: true,
    visible: true,
    color: 'success',
  },
  warning: {
    autohide: true,
    visible: true,
    color: 'warning',
  },
  info: {
    autohide: true,
    visible: true,
    color: 'primary',
  },
  error: {
    autohide: true,
    visible: true,
    color: 'danger',
  },
}

const Notification = ({ message, type, id }) => {
  const { autohide, visible, color } = notificationTypes[type]

  return (
    <CToast autohide={autohide} visible={visible} color={color} placement="top-end">
      <div className="d-flex">
        <CToastBody>{message}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )
}

Notification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.number,
}

export default Notification
