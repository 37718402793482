import axios from 'axios'
import env from '@/enviroments'

export default class RemittanceBaseService {
  baseUrl = `${env.remittancesAPI}`
  remittanceType = ''
  #axiosClient = null

  async create(url, data) {
    const headers = this.getBasicHeader()
    let config = {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }
    return await this.#axiosClient.post(url, data, config)
  }

  async get(url, params) {
    const headers = this.getBasicHeader()
    let config = {
      headers,
      params: { ...params },
    }
    return await this.#axiosClient.get(url, config)
  }

  async update(url, data) {
    const headers = this.getBasicHeader()
    let config = {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }
    return await this.#axiosClient.put(url, data, config)
  }

  constructor(navigate, notification) {
    this.#axiosClient = axios.create({
      baseURL: env.remittancesAPI,
    })
    this.setupResponseInterceptor(navigate, notification)
    // this.remittanceType = type
  }

  setupResponseInterceptor(navigate, notification) {
    this.#axiosClient.interceptors.response.clear()
    this.#axiosClient.interceptors.response.use(
      (response) => Promise.resolve(response.data),
      (error) => {
        if (error.response) {
          const response = error.response
          switch (response.status) {
            case 400:
            case 403:
            case 406:
              if (response['data']) {
                if (response['data']['detail']) {
                  if (response['data']['detail']['message']) {
                    notification.warning('Error', response['data']['detail']['message'])
                  } else {
                    notification.warning('Error', response['data']['detail'])
                  }
                } else {
                  notification.warning('Error', response['data']['error_description'])
                }
              } else {
                notification.warning('Error', response)
              }
              break
            case 422:
              response['data']['detail'].forEach((error) => {
                notification.warning('Error', error['msg'])
              })
              break
            case 401:
              notification.info('Lo siento!', 'Sin autorización')

              break
            case 404:
              notification.warning('Lo siento!', 'El recurso no ha sido encontrado')
              break

            case 500:
            case 501:
            case 502:
            case 503:
              notification.error('Error', 'Algo ha salido mal contacte a su administrador')
              break
            default:
              notification.warning('Error', JSON.stringify(error))
          }
        } else {
          notification.error('Error', 'No se ha podido conectar con el servicio')
        }
        return error
      },
    )
  }

  getBasicHeader() {
    return {
      RemittanceType: this.remittanceType,
      SystemType: 'CashMax',
    }
  }
}
