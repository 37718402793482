import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useService } from '@/hooks'
import PropTypes from 'prop-types'
import { EndowmentContext } from './EndowmentContext'

export const EndowmentContextProvider = ({ children }) => {
  const [endowment, setEndowment] = useState({})
  const [endowmentStatus, setEndowmentStatus] = useState(0)
  const [isEditing, setEditing] = useState(false)
  const cashCountService = useService('cashCount')
  const { requestId } = useParams()

  useEffect(() => {
    if (requestId) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId])

  const getData = async () => {
    const response = await cashCountService.getCashierRequest(requestId)

    if (response.code || (response.response && response.response.status !== 200)) {
      return
    }

    setEndowment({ ...response })
    setEditing(true)
    setEndowmentStatus(response['status'])
  }

  const value = { endowment, setEndowment, isEditing, endowmentStatus, setEndowmentStatus }

  return <EndowmentContext.Provider value={value}>{children}</EndowmentContext.Provider>
}

EndowmentContextProvider.propTypes = {
  children: PropTypes.node,
}

export default EndowmentContextProvider
