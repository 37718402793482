import axios from 'axios'
import env from '@/enviroments'
import { userStorageKey } from '@/utils/constants'

export const setUpAxios = (navigate, notification) => {
  const user = JSON.parse(localStorage.getItem(userStorageKey))
  const headers = {}
  if (user && user.access_token) {
    headers.Authorization = `Bearer ${user.access_token}`
  }
  axios.defaults.baseURL = env.apiUrl
  axios.defaults.headers = headers

  setupResponseInterceptor(navigate, notification)
  return axios
}

const notAllowedNotification = [
  'GetCashierNotifications',
  'ChangeCashierNotificationsVisibility',
  'AddCashierNotification',
]

export const setupResponseInterceptor = (navigate, notification) => {
  axios.interceptors.response.clear()
  axios.interceptors.response.use(
    (response) => Promise.resolve(response.data),
    (error) => {
      if (error['config']) {
        let { url } = error['config']
        let paths = url.split('/')

        if (notAllowedNotification.includes(paths[1])) {
          return error
        }
      }
      if (error.response) {
        const response = error.response
        switch (response.status) {
          case 400:
          case 403:
          case 406:
            if (response['data']) {
              if (response['data']['detail']) {
                if (response['data']['detail']['message']) {
                  notification.warning('Error', response['data']['detail']['message'])
                } else {
                  notification.warning('Error', response['data']['detail'])
                }
              }

              if (response['data']['Message']) {
                notification.warning('Error', response['data']['Message'])
              } else {
                notification.warning('Error', response['data']['error_description'])
              }
            } else {
              notification.warning('Error', JSON.stringify(response))
            }
            break
          case 401:
            notification.info('Wow!', 'Tu sesion ha expirado')
            localStorage.removeItem(userStorageKey)
            navigate('/login')

            break
          case 404:
            notification.warning('Lo siento!', 'El recurso no ha sido encontrado')
            break

          case 500:
          case 501:
          case 502:
          case 503:
            notification.error('Error', 'Algo ha salido mal contacte a su administrador')
            break
          default:
            notification.warning('Error', JSON.stringify(error))
        }
      } else {
        notification.error('Error', 'No se ha podido conectar con el servicio')
      }
      return error
    },
  )
}
