import React from 'react'
import { CRow, CCol } from '@coreui/react'
import { toMMDDYYYYHH } from '@/utils/formatters'

const VoucherFooter = () => (
  <>
    <CRow className="mt-3 mb-4">
      <CCol lg={12} className="text-center">
        <small className="">
          Por este medio declaro que la información que he proporcionado la he hecho de manera
          voluntaria y la misma es verdadera, por lo que autorizo a FID S.A a confirma la
          información declarada en este documento y a solicitar información adicional, de cualquier
          fuente, persona natural o jurídica; lo anterior en cumplimiento con la diligencia que la
          institución está obligada a realizar por la legislación, normativas, políticas y
          procedimientos internos.
        </small>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg={12} className="text-center mt-4">
        <small className="">-------------------------------------</small>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <small className="">Firma del cliente</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg={12} className="text-start mt-4">
        <small className="">{toMMDDYYYYHH(null)}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg={12} className="text-start mt-2">
        <small className="">MANAGUA , Managua</small>
      </CCol>
    </CRow>
  </>
)

export default VoucherFooter
