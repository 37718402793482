import { userStorageKey, permissionStorageKey } from '@/utils/constants'

export const useAuthUser = () => {
  const isLoggedUser = () => {
    const loggedInUser = localStorage.getItem(userStorageKey)
    const loggedInfo = JSON.parse(loggedInUser)
    if (loggedInUser && loggedInfo['access_token']) {
      return true
    }
    return false
  }

  const logOut = () => {
    localStorage.removeItem(userStorageKey)
    localStorage.removeItem(permissionStorageKey)
  }

  const getAuthInfo = () => {
    if (!isLoggedUser()) {
      logOut()
      return
    }
    return JSON.parse(localStorage.getItem(userStorageKey))
  }

  const isLogged = isLoggedUser()
  const user = getAuthInfo()

  return {
    isLogged,
    logOut,
    getAuthInfo,
    user,
  }
}
