export const cashierTypes = {
  Pago: 0,
  Tesoreria: 1,
}

export const cashierRequestStatusColor = {
  Creada: 'secondary',
  EnProceso: 'info',
  AprobadaPorTesoreria: 'success',
  Finalizada: 'success',
  Cancelada: 'danger',
  Rechazada: 'danger',
}

export const cashierRequestStatusName = {
  Creada: 'Creada',
  EnProceso: 'En Proceso',
  AprobadaPorTesoreria: 'Aprobada Por Tesorería',
  Finalizada: 'Finalizada',
  Cancelada: 'Cancelada',
  Rechazada: 'Rechazada',
}

export const cashierRequestSupervisorStatus = {
  Creada: 0,
  'En Proceso': 1,
  'Aprobada Por Tesorería': 2,
  Cancelada: 4,
  Rechazada: 5,
}

export const cashierRequestStatus = {
  Creada: 0,
  EnProceso: 1,
  AprobadaPorTesoreria: 2,
  Finalizada: 3,
  Cancelada: 4,
  Rechazada: 5,
}

export const cashierRequestStatusByCurrentState = {
  0: {
    Creada: 0,
    Cancelada: 4,
  },
  1: {
    'En Proceso': 1,
  },
  2: {
    'Aprobada Por Tesorería': 2,
    Finalizada: 3,
  },
  3: {
    Finalizada: 3,
  },
}

export const cashierRequestType = {
  Dotaje: 0,
  Prestamo: 1,
}

export const cashierNotificationType = {
  SolicitudDotaje: 0,
  MinimoEnCaja: 1,
  AdvertenciaDeFondosInsucifientes: 2,
  ActualizacionSolicitudDotaje: 3,
  PagoRemesa: 4,
}

export const cashierNotificationTypeName = {
  0: 'Solicitud dotaje',
  1: 'Minimo en caja',
  2: 'Advertencia De fondos insucifientes',
  3: 'Actualización solicitud dotaje',
  4: 'Pago remesa',
}

export const transactionType = {
  Desembolso: 0,
  ReversaDeDesembolso: 1,
  Pago: 2,
  ReversaDePago: 3,
  PagoServicio: 4,
  Arqueo: 5,
  Ingresos: 6,
  Egresos: 7,
  Sobrante: 8,
  MesaDeCambio: 9,
  Cambio: 10,
  ReversaMesaDeCambio: 11,
  AjustePorSobrante: 12,
  AjustePorFaltante: 13,
  PagoCredex: 14,
  ReversaPagoCredex: 15,
  PrimaDesembolso: 16,
  CompraTC: 17,
  ReversaCompraTC: 18,
  PagoTC: 19,
  ReversaPagoTC: 20,
  Dotaje: 21,
  RetiroTesoreria: 22,
}

export const cashierRequestDeniedModifyStatus = [5, 3]
