import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol, CContainer } from '@coreui/react'
import { cilBellExclamation } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { toHHMM } from '@/utils/formatters'
import { cashierNotificationTypeName } from '@/utils/constants'

const CashierNotificationItem = ({ notification }) => {
  return (
    <>
      <CContainer>
        <CRow className="mt-2">
          <CCol lg={1}>
            <CIcon icon={cilBellExclamation} className="notification-icon" />
          </CCol>
          <CCol lg={10} className="p-0 ps-3">
            <h6 className="m-0">{cashierNotificationTypeName[notification['type']]}</h6>
            <small className="m-0 text-small">{notification['description']}</small>
          </CCol>
          <CCol lg={1} className="text-left p-0 text-small text-bold">
            <small className="m-0">{toHHMM(notification['createdOn'])}</small>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

CashierNotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
}

export default CashierNotificationItem
