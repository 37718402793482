import {
  RemittanceCatalogsService,
  RemittanceCustomerService,
  RemittanceService,
  RemittanceReportService,
} from '@/services'

// eslint-disable-next-line import/no-anonymous-default-export
export const serviceMapper = {
  remittanceCatalogs: RemittanceCatalogsService,
  remittanceCustomer: RemittanceCustomerService,
  remittance: RemittanceService,
  remittanceReport: RemittanceReportService,
}
