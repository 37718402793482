import React from 'react'
import PropTypes from 'prop-types'
import { CTable, CRow, CCol } from '@coreui/react'

const ReportAmount = ({ columns, items, footer }) => {
  return (
    <CRow>
      <CCol xs={12}>
        <CTable responsive columns={columns} items={items} footer={footer} className="mt-3" />
      </CCol>
    </CRow>
  )
}

ReportAmount.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  footer: PropTypes.array.isRequired,
}

export default ReportAmount
