import { AxiosError } from 'axios'
import BaseService from '../baseService'
import { userStorageKey } from '@/utils/constants'

export default class UserService extends BaseService {
  baseUrl = 'Token/'

  async login(data) {
    const response = await this.createEntity(data)
    if (response.response && response.response.status !== 200) {
      return false
    }
    // if (!response.response) {
    //   return false
    // }

    this.setUser(response)
    return true
  }

  async resetPassword(data) {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const response = await super.post('/Account/Recover/', data, config)

    if (response instanceof AxiosError) {
      return false
    }

    if (response.response && response.response.status !== 200) {
      return false
    }

    return true
  }

  setUser(user) {
    localStorage.setItem(userStorageKey, JSON.stringify(user))
  }

  getUser(user) {
    localStorage.getItem(userStorageKey)
  }
}
