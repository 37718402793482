import React from 'react'
import VoucherFooter from './Footer'
import VoucherHeader from './Header'
import TransactionAmount from './TransactionAmount'
import TransactionDetail from './TransactionDetail'
import './index.scss'
import { CContainer } from '@coreui/react'

export class VoucherComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="main-content text-small">
          <CContainer className="ps-0">
            <VoucherHeader />
            <TransactionDetail {...this.props} />
            <TransactionAmount {...this.props} />
            <VoucherFooter />
          </CContainer>
        </div>
      </div>
    )
  }
}

const VoucherDocument = React.forwardRef((props, ref) => {
  return <VoucherComponent {...props} ref={ref} />
})

export default VoucherDocument
