import BaseService from '../baseService'
import { permissionStorageKey } from '@/utils/constants'

export default class PermissionService extends BaseService {
  baseUrl = 'api/Permission/'

  setPermissions(permissions) {
    localStorage.setItem(permissionStorageKey, JSON.stringify(permissions))
  }

  getPermissions() {
    localStorage.getItem(permissionStorageKey)
  }
}
