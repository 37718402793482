import { permissionStorageKey, userStorageKey } from '@/utils/constants'
import { useNotification } from '@/hooks'

export const useStorage = () => {
  const notification = useNotification()

  const getItem = (key) => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return null
  }

  const setItem = (key, value) => {
    const item = getItem(permissionStorageKey)

    if (item) {
      notification.info(`Error en establecer clave [${key}]!`, 'No se permite sobrescribir valores')
      return false
    }

    const result = localStorage.setItem(key, value)
    if (result) {
      return true
    }
    return false
  }

  const getPermissions = () => {
    const item = getItem(permissionStorageKey)

    if (!item) {
      return []
    }

    if (item && !item.length) {
      return []
    }

    return item
  }

  const getAuthUser = (user) => {
    const item = getItem(userStorageKey)

    if (!item) {
      return null
    }

    return item
  }

  const permissions = getPermissions()

  return {
    permissions,
    getItem,
    setItem,
    getPermissions,
    getAuthUser,
  }
}
