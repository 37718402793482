import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  SuccessNotification,
  ErrorNotification,
  WarningNotification,
  InfoNotification,
} from '@/components'
import { NotificationContext } from './NotificationContext'
import { CToaster } from '@coreui/react'

export const NotificationContextProvider = ({ children }) => {
  const dispatch = useDispatch()

  const [toast, addToast] = useState(0)
  const toaster = useRef()

  const remove = (id) => {
    dispatch({ type: 'DELETE_TOAST', payload: id })
  }

  const success = (tittle, message) => {
    addToast(SuccessNotification.call(this, { tittle, message }))
  }

  const warning = (tittle, message) => {
    addToast(WarningNotification.call(this, { tittle, message }))
  }

  const info = (tittle, message) => {
    addToast(InfoNotification.call(this, { tittle, message }))
  }

  const error = (tittle, message) => {
    addToast(ErrorNotification.call(this, { tittle, message }))
  }

  const value = { success, warning, info, error, remove }

  return (
    <NotificationContext.Provider value={value}>
      <CToaster ref={toaster} push={toast} placement="top-end" />
      {children}
    </NotificationContext.Provider>
  )
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node,
}

export default NotificationContextProvider
