import React from 'react'
import ReportFooter from './Footer'
import ReportHeader from './Header'
import ReportAmount from './ReportAmount'
import ReportDetail from './ReportDetail'
import './index.scss'
import { CContainer } from '@coreui/react'

export class ReportComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="main-content text-small">
          <CContainer className="ps-0">
            <ReportHeader />
            <ReportDetail {...this.props} />
            <ReportAmount {...this.props} />
            <ReportFooter />
          </CContainer>
        </div>
      </div>
    )
  }
}

const ReportDocument = React.forwardRef((props, ref) => {
  return <ReportComponent {...props} ref={ref} />
})

export default ReportDocument
