import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '@/utils/formatters'
import { useService, useNotification } from '@/hooks'
import { toCurrencyFormat } from '@/utils/formatters'
import { cashCountRemittanceReportColumns, currencyNameMapper } from '@/utils/constants'

export const useReportGenerator = () => {
  const notification = useNotification()
  const remittanceReportService = useService('remittanceReport')
  const { denominations } = useSelector((state) => state.cashCount)
  const [dataReport, setDataReport] = useState([])

  const processData = async (payloadRequest) => {
    const response = await remittanceReportService.getCashCountReport(payloadRequest)

    if (response.status === 200) {
      if (response['data']['rows'].length === 0) {
        notification.error('Atencion!', 'Sin registros')
        return
      }
      if (!isEmpty(response['data'])) {
        const columns = processHeaders()
        const items = processItems(response['data'])
        const footer = processFooter(response['data'])
        setDataReport({
          columns,
          items,
          footer,
        })
      }
    }
  }

  const processHeaders = () => {
    let currencies = [...Object.keys(denominations)]
    const constantHeaders = [...cashCountRemittanceReportColumns]
    currencies.forEach((currencie) => {
      constantHeaders.push({
        key: currencie,
        label: currencyNameMapper[currencie],
        _props: { className: 'text-center w-10', scope: 'col' },
      })
    })
    return [...constantHeaders]
  }

  const processItems = (data) => {
    const { rows } = data
    let currencies = [...Object.keys(denominations)]
    let remittances = [...rows]
    remittances.forEach((remittance) => {
      let cellProps = {
        sequence: { className: 'text-end', scope: 'row' },
        remittance_code: { className: 'text-center', scope: 'row' },
      }
      if (!remittance['sequence']) {
        remittance['sequence'] = '000'
      }

      currencies.forEach((currencie) => {
        if (remittance['destination_currency'] === currencie) {
          remittance[currencie] = toCurrencyFormat(remittance['destination_amount'])
        } else {
          remittance[currencie] = '0.00'
        }
        cellProps[currencie] = { className: 'text-end', scope: 'row' }
      })
      remittance['_cellProps'] = { ...cellProps }
    })
    return [...remittances]
  }

  const processFooter = (data) => {
    const { rows } = data
    let currencies = [...Object.keys(denominations)]
    let totals = []
    let footerColumns = []
    const constantHeaders = [...cashCountRemittanceReportColumns]
    rows.forEach((remittance) => {
      let total = totals.find(
        (totalItem) => totalItem['currency'] === remittance['destination_currency'],
      )
      if (total) {
        total['amount'] += parseFloat(remittance['destination_amount'])
      } else {
        let totalItem = {
          currency: remittance['destination_currency'],
          amount: parseFloat(remittance['destination_amount']),
        }
        totals.push(totalItem)
      }
    })
    currencies.forEach((currency) => {
      constantHeaders.push({
        key: currency,
        label: currencyNameMapper[currency],
        _props: { className: 'text-end', scope: 'col' },
      })
    })
    footerColumns = constantHeaders.map((header, index) => {
      if (index === 0) {
        return {
          label: 'Total',
          _props: { className: 'text-center font-weight-bold', scope: 'row' },
        }
      }
      let total = totals.find((totalItem) => totalItem['currency'] === header['key'])
      if (total) {
        return {
          label: toCurrencyFormat(total['amount']) ?? '0.00',
          _props: { className: 'text-end font-weight-bold', scope: 'row' },
        }
      }
      return ''
    })

    return [...footerColumns]
  }

  return {
    processData,
    dataReport,
  }
}
