import React from 'react'
import { CRow, CCol } from '@coreui/react'
import { useSelector } from 'react-redux'
import logo from '@/assets/brand/logo-remittance.png'

const ReceiptHeader = () => {
  const { cashierSellingPointName } = useSelector((state) => state.cashCount)
  return (
    <>
      <CRow>
        <CCol xs={6} sm={6} className="text-start">
          <small className="">CIR</small>
        </CCol>
        <CCol xs={6} sm={6} className="text-end">
          <div id="logo">
            <img src={logo} width={80} height={25} alt="Logo" />
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={3} className="text-start">
          <small className="">Agencia:</small>
        </CCol>
        <CCol xs={9} className="text-start">
          <small className=" fw-bolder">{cashierSellingPointName}</small>
        </CCol>
      </CRow>
    </>
  )
}

export default ReceiptHeader
