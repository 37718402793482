import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CInputGroupText,
  CRow,
  CCol,
  CForm,
  CInputGroup,
  CFormInput,
} from '@coreui/react'
import { cilAt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { emailRegExp } from '@/utils/constants'
import { LoadingButton } from '@/components'
import { useService, useNotification } from '@/hooks'

const ResetPasswordModal = ({ visible, cancel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const notification = useNotification()
  const userService = useService('user')
  const [disableButton, setDisableButton] = useState(false)

  const validateAccess = async (data) => {
    setDisableButton(true)

    let payload = {
      email: data['email'],
      recoveryType: 'password',
      scope: 'cashmax',
    }

    const response = await userService.resetPassword(payload)

    if (response) {
      notification.success('Listo!', 'Se ha enviado la nueva contraseña al correo proporcionado')
      close()
      return
    }

    setDisableButton(false)
  }

  const close = () => {
    cancel()
  }

  return (
    <>
      <CModal alignment="center" backdrop="static" visible={visible} onClose={close}>
        <CForm onSubmit={handleSubmit(validateAccess)}>
          <CModalHeader>
            <CModalTitle>Restaurar contraseña</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol lg={12} md={12} sm={12}>
                <p>Ingrese su correo electronico</p>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg={12} md={12} sm={12}>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilAt} />
                  </CInputGroupText>
                  <CFormInput
                    placeholder="Correo"
                    autoComplete="email"
                    feedback={
                      errors.email && errors.email.type === 'required'
                        ? 'Este campo es requerido'
                        : 'Correo inválido'
                    }
                    invalid={errors.email ? true : false}
                    {...register('email', {
                      required: true,
                      pattern: emailRegExp,
                    })}
                  />
                </CInputGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" className="text-white" onClick={cancel}>
              Cancelar
            </CButton>
            <LoadingButton
              color="primary"
              disabled={disableButton}
              className="px-4 text-white"
              type="submit"
              disableButton={disableButton}
              text={disableButton ? 'Validando..' : 'Validar'}
            />
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  )
}

ResetPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default ResetPasswordModal
