import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CMaskInput } from '@/components'
import { CCol, CRow, CInputGroup } from '@coreui/react'

const CashierLimitItem = ({ currency, limit, updateLimit }) => {
  const [limitValue, setLimitValue] = useState({ ...limit })

  useEffect(() => {
    setLimitValue({ ...limit })
  }, [limit])

  return (
    <CRow>
      <CCol lg={12} md={12} sm={12} className="justify-content-center text-start">
        <label className="text-bold text-start">Minimo</label>
        <CInputGroup className="mb-3">
          <CMaskInput
            size="sm"
            maskOptions={{
              mask: '0000',
            }}
            onChange={(env, _maskRef) => {
              updateLimit('min', env.target.value, currency)
              _maskRef.updateValue()
            }}
            value={limitValue.min}
          />
        </CInputGroup>
      </CCol>
      <CCol lg={12} md={12} sm={12} className="justify-content-center text-start mt-2">
        <label className="text-bold text-start">Máximo</label>
        <CInputGroup className="mb-3">
          <CMaskInput
            size="sm"
            maskOptions={{
              mask: '000000',
            }}
            onChange={(env, _maskRef) => {
              updateLimit('max', env.target.value, currency)
              _maskRef.updateValue()
            }}
            value={limitValue.max}
          />
        </CInputGroup>
      </CCol>
    </CRow>
  )
}

CashierLimitItem.propTypes = {
  currency: PropTypes.string.isRequired,
  limit: PropTypes.object.isRequired,
  updateLimit: PropTypes.func.isRequired,
}

export default CashierLimitItem
