import React from 'react'
import {
  APPLICATION_ROOT,
  DASHBOARD,
  CASHCOUNT_PANEL,
  REMITTANCES_PANEL,
  ADMIN_PANEL,
} from './routes'
import cashCountRouter from './cashCountRouter'
import remittancesRouter from './remittancesRouter'
import adminRouter from './adminRouter'

const Dashboard = React.lazy(() => import('@/pages/dashboard/Dashboard'))
const CashCount = React.lazy(() => import('@/pages/cashCount/CashCount'))
const Remittances = React.lazy(() => import('@/pages/remittances/Remittances'))
const Administration = React.lazy(() => import('@/pages/admin/Administration'))

const router = [
  { path: APPLICATION_ROOT, exact: true, name: 'Home' },
  { path: DASHBOARD, name: 'Dashboard', element: Dashboard },
  {
    path: CASHCOUNT_PANEL,
    name: 'Caja',
    element: CashCount,
    childs: cashCountRouter,
  },
  {
    path: REMITTANCES_PANEL,
    name: 'Remesas',
    element: Remittances,
    childs: remittancesRouter,
  },
  {
    path: ADMIN_PANEL,
    name: 'Administracion',
    element: Administration,
    childs: adminRouter,
  },
]

export default router
