import { useStorage } from '@/hooks'
import { permissionEnum } from '@/utils/constants'
import { AbilityBuilder, Ability } from '@casl/ability'

export const useAcl = () => {
  const { permissions } = useStorage()

  const defineAbilities = (user) => {
    // eslint-disable-next-line no-unused-vars
    const { can, cannot, build } = new AbilityBuilder(Ability)
    permissions.forEach((permission) => {
      can(permissionEnum[permission['Action']], permission['Resource'])
    })
    return build()
  }

  const abilities = defineAbilities()

  return {
    abilities,
    defineAbilities,
  }
}
