import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from '@/utils/formatters'
import { useService, useNotification } from '@/hooks'
import { toCurrencyFormat } from '@/utils/formatters'

export const useExcelGenerator = () => {
  const notification = useNotification()
  const remittanceReportService = useService('remittanceReport')
  const { denominations } = useSelector((state) => state.cashCount)
  const [excelData, setExcelData] = useState([])

  const processData = async (payloadRequest) => {
    const response = await remittanceReportService.getCashCountReport(payloadRequest)

    if (response.status === 200) {
      if (response['data']['rows'].length === 0) {
        notification.error('Atencion!', 'Sin registros')
        return
      }
      if (!isEmpty(response['data'])) {
        const items = processItems(response['data'])
        setExcelData([...items])
      }
    }
  }

  const processItems = (data) => {
    const { rows } = data
    let currencies = [...Object.keys(denominations)]
    let remittances = [...rows]
    let excelRows = []
    let totals = []
    remittances.forEach((remittance) => {
      let row = {}
      if (!remittance['sequence']) {
        row['Consecutivo'] = '000'
      } else {
        row['Consecutivo'] = remittance['sequence']
      }
      row['N° Remesa'] = remittance['remittance_code']
      row['Beneficiario'] = remittance['beneficiary_name']

      currencies.forEach((currencie) => {
        if (remittance['destination_currency'] === currencie) {
          row[currencie] = toCurrencyFormat(remittance['destination_amount'])
        } else {
          row[currencie] = '0.00'
        }
      })

      excelRows.push(row)
    })

    currencies.forEach((key) => {
      let remittancesList = remittances.filter(
        (remittance) => remittance['destination_currency'] === key,
      )
      const total = remittancesList.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue['destination_amount']),
        0,
      )

      let totalItem = {
        currency: key,
        amount: parseFloat(total),
      }
      totals.push(totalItem)
    })

    excelRows = addTotals(totals, excelRows)

    return [...excelRows]
  }

  const addTotals = (totals, excelRows) => {
    let row = {}
    row['Consecutivo'] = 'Total'
    row['N° Remesa'] = ''
    row['Beneficiario'] = ''

    totals.forEach((total) => {
      row[total['currency']] = toCurrencyFormat(total['amount'])
    })

    excelRows.push(row)
    return excelRows
  }

  return {
    processData,
    excelData,
  }
}
