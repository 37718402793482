import React from 'react'
import PropTypes from 'prop-types'
import { CRow, CCol } from '@coreui/react'

const ReportDetail = ({ date, branch, cashier }) => (
  <>
    <CRow className="mt-3">
      <CCol xs={2} sm={6} className="text-start ">
        <small className="fw-bolder">Fecha:</small>
      </CCol>
      <CCol xs={6} sm={6} className="text-start">
        <small className="fw-bolder">{date}</small>
      </CCol>
      <CCol xs={2} sm={6} className="text-start">
        <small className="fw-bolder">Cajero:</small>
      </CCol>
      <CCol xs={2} sm={6} className="text-start">
        <small className="fw-bolder">{cashier}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={3} sm={6} className="text-start ">
        <small className="fw-bolder">Sucursal:</small>
      </CCol>
      <CCol xs={9} sm={6} className="text-start">
        <small className="fw-bolder">{branch}</small>
      </CCol>
    </CRow>
  </>
)

ReportDetail.propTypes = {
  date: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  cashier: PropTypes.string.isRequired,
}

export default ReportDetail
