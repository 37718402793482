import React from 'react'

import {
  ADMIN_CASHIER,
  ADMIN_CASHIER_USER,
  ADMIN_CASHIER_DETAIL,
  ADMIN_CASHIER_NEW,
  ADMIN_CASHIER_USER_DETAIL,
  ADMIN_CASHIER_USER_NEW,
} from './routes'

const CashierList = React.lazy(() => import('@/features/admin/cashierList/CashierList'))
const CashierDetail = React.lazy(() => import('@/features/admin/cashierDetail/CashierDetail'))
const CashierNew = React.lazy(() => import('@/features/admin/cashierNew/CashierNew'))
const CashierUserList = React.lazy(() => import('@/features/admin/cashierUserList/CashierUserList'))
const CashierUserDetail = React.lazy(() =>
  import('@/features/admin/cashierUserDetail/CashierUserDetail'),
)
const CashierUserNew = React.lazy(() => import('@/features/admin/cashierUserNew/CashierUserNew'))

const adminRouter = [
  { path: ADMIN_CASHIER, name: 'Cajas', element: CashierList },
  { path: ADMIN_CASHIER_DETAIL, name: 'Nuevo cajero', element: CashierDetail },
  { path: ADMIN_CASHIER_NEW, name: 'Detalles de cajero', element: CashierNew },
  { path: ADMIN_CASHIER_USER, name: 'Asignación de usuarios', element: CashierUserList },
  { path: ADMIN_CASHIER_USER_NEW, name: 'Nueva Asignacion', element: CashierUserNew },
  { path: ADMIN_CASHIER_USER_DETAIL, name: 'Detalles de asingacion', element: CashierUserDetail },
]

export default adminRouter
