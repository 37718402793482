import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useService } from '@/hooks'
import { CashierNotification } from './CashierNotification'

export const CashierNotificationProvider = ({ children }) => {
  const { cashierId } = useSelector((state) => state.cashCount)
  const cashCountService = useService('cashCount')
  const [notificationInterval, setNotificationInterval] = useState(null)
  const [isDropdownShowed, setIsDropdownShowed] = useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (cashierId) {
      stopListen()
      startListen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierId, isDropdownShowed])

  const startListen = () => {
    setNotificationInterval(setInterval(getNotifications, 3500, isDropdownShowed))
  }

  const stopListen = () => {
    clearInterval(notificationInterval)
    setNotificationInterval(null)
  }

  const getNotifications = async (isShowed) => {
    if (isShowed) return
    const response = await cashCountService.getCashierNotifications(cashierId)

    if (response.code || (response.response && response.response.status !== 200)) {
      return
    }

    if (isShowed) return
    setNotifications(response)
  }

  const value = {
    notificationInterval,
    setNotificationInterval,
    notifications,
    setNotifications,
    stopListen,
    isDropdownShowed,
    setIsDropdownShowed,
  }

  return <CashierNotification.Provider value={value}>{children}</CashierNotification.Provider>
}

CashierNotificationProvider.propTypes = {
  children: PropTypes.node,
}

export default CashierNotificationProvider
