import React from 'react'
import { CContainer } from '@coreui/react'
import './index.scss'
import ReceiptFooter from './Footer'
import ReceiptHeader from './Header'
import TransactionAmount from './TransactionAmount'
import TransactionDetail from './TransactionDetail'

class ReceiptComponent extends React.PureComponent {
  render() {
    return (
      <div className="main-content text-small">
        <CContainer className="ps-0 pe-3">
          <ReceiptHeader />
          <TransactionDetail {...this.props} />
          <TransactionAmount {...this.props} />
          <ReceiptFooter />
        </CContainer>
      </div>
    )
  }
}

const ReceiptDocument = React.forwardRef((props, ref) => {
  return <ReceiptComponent {...props} ref={ref} />
})

export default ReceiptDocument
