import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@/components'
import { useNavigate } from 'react-router-dom'
import { isEmpty as isEmptyObject } from '@/utils/formatters'
import {
  CCol,
  CFormSelect,
  CRow,
  CButton,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMonitor, cilUser, cilCalendar } from '@coreui/icons'
import { ADMIN_CASHIER_USER } from '@/router'

const CashierUserForm = ({ submit, defaultValue, disableButton, cashiers, users }) => {
  const navigate = useNavigate()
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      status: true,
    },
  })

  useEffect(() => {
    if (!isEmptyObject(defaultValue)) {
      reset({ ...defaultValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <CForm onSubmit={handleSubmit(submit)}>
      <CRow>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Caja</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilMonitor} />
            </CInputGroupText>
            <CFormSelect
              {...register('idCashier', {
                required: true,
              })}
            >
              <option defaultValue disabled>
                Cajero...
              </option>
              {cashiers.map((type, index) => (
                <option key={index} value={type['id']}>
                  {type['cashierName']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Usuario</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilUser} />
            </CInputGroupText>
            <CFormSelect
              {...register('idUser', {
                required: true,
              })}
            >
              <option defaultValue disabled>
                Usuario...
              </option>
              {users.map((type, index) => (
                <option key={index} value={type['id']}>
                  {type['name']}
                </option>
              ))}
            </CFormSelect>
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Fecha Inicio</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilCalendar} />
            </CInputGroupText>
            <CFormInput
              placeholder="Fecha Inicio"
              {...register('dateStart', { required: true })}
              type="date"
            />
          </CInputGroup>
        </CCol>
        <CCol lg={6} md={6} sm={12} className="text-start mb-3">
          <label className="text-bold">Fecha Fin</label>
          <CInputGroup>
            <CInputGroupText>
              <CIcon icon={cilCalendar} />
            </CInputGroupText>
            <CFormInput
              placeholder="Fecha Fin"
              className="form-control"
              {...register('dateEnd', { required: true })}
              type="date"
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={12} md={12} sm={12} className="text-start mb-3">
          <CFormCheck className="text-start" {...register('status', {})} label="Activo" />
        </CCol>
      </CRow>
      <CRow>
        <CCol lg={6} xs={6} className="text-start">
          <LoadingButton
            color="primary"
            type="submit"
            className="px-4 text-white"
            disableButton={disableButton}
            disabled={disableButton}
            text="Guardar"
          />
        </CCol>
        <CCol lg={6} xs={6} className="text-end">
          <CButton
            color="secondary"
            className="text-white"
            onClick={() => {
              navigate({ pathname: ADMIN_CASHIER_USER })
            }}
          >
            Cancelar
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

CashierUserForm.propTypes = {
  submit: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  disableButton: PropTypes.bool.isRequired,
  cashiers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
}

export default CashierUserForm
