import React from 'react'
import PropTypes from 'prop-types'
import CashierDenominationItem from './CashierDenominationItem'

const CashierDenominationList = ({ denominations, updateDenomination }) => {
  return (
    <>
      {denominations.map((denomination, index) => (
        <CashierDenominationItem
          key={index}
          denomination={denomination}
          updateDenomination={updateDenomination}
        />
      ))}
    </>
  )
}

CashierDenominationList.propTypes = {
  denominations: PropTypes.array.isRequired,
  updateDenomination: PropTypes.func.isRequired,
}

export default CashierDenominationList
