import React from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CBadge,
} from '@coreui/react'
import { useSelector } from 'react-redux'
import { useCashierNotification, useService } from '@/hooks'
import { cilBell, cilCheckCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CashierNotificationItem } from '../notifications'

const NotificationDropdown = () => {
  const { notifications, setIsDropdownShowed } = useCashierNotification()
  const { cashierId } = useSelector((state) => state.cashCount)
  const cashCountService = useService('cashCount')

  const updateNotifications = async () => {
    if (notifications.length === 0) {
      return
    }
    const response = await cashCountService.updateCashierNotifications({
      notificationList: notifications.map((notification) => notification['id']),
      cashierId: cashierId,
    })

    if (response.code || (response.response && response.response.status !== 200)) {
      return
    }
  }

  const showedDropDown = (value) => {
    setIsDropdownShowed(value)
  }

  return (
    <CDropdown
      variant="nav-item"
      onClick={updateNotifications}
      onShow={() => showedDropDown(true)}
      onHide={() => showedDropDown(false)}
    >
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon icon={cilBell} size="xxl" />
        {notifications.length > 0 && (
          <CBadge color="danger" position="top-start" shape="rounded-pill">
            {notifications.length}+ <span className="visually-hidden">unread messages</span>
          </CBadge>
        )}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 notification-dropdown" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Actividades recientes
        </CDropdownHeader>
        {notifications.length > 0 &&
          notifications.map((notification, index) => {
            return (
              <CDropdownItem
                key={index}
                component={() => {
                  if (index === notifications.length - 1) {
                    return <CashierNotificationItem notification={notification} />
                  }
                  return (
                    <>
                      <CashierNotificationItem notification={notification} />

                      <CDropdownDivider />
                    </>
                  )
                }}
              ></CDropdownItem>
            )
          })}
        {notifications.length === 0 && (
          <CDropdownItem component="button">
            <CIcon icon={cilCheckCircle} className="me-2" />
            Sin actividades recientes
          </CDropdownItem>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default NotificationDropdown
