import React from 'react'
import PropTypes from 'prop-types'
import { useNotification } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { AxiosSetUpContext } from './AxiosSetUpContext'
import { setUpAxios } from '@/lib'

export const AxiosSetUpContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const notification = useNotification()

  const setUp = () => {
    setUpAxios(navigate, notification)
  }

  const value = { setUp }

  return <AxiosSetUpContext.Provider value={value}>{children}</AxiosSetUpContext.Provider>
}

AxiosSetUpContextProvider.propTypes = {
  children: PropTypes.node,
}

export default AxiosSetUpContextProvider
