import { CFormInput } from '@coreui/react'
import React, { useState, forwardRef, useEffect } from 'react'
import { useIMask } from 'react-imask'

// eslint-disable-next-line react/display-name
const CMaskInput = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { maskOptions, onChange, ...rest } = props

  const [opts, setOpts] = useState(maskOptions)
  const { ref: IMaskInput } = useIMask(opts, {
    onAccept(_value, _maskRef, e) {
      if (!e) return

      _maskRef.updateValue()
      onChange?.(e, _maskRef)
    },
  })
  const inputMaskRef = IMaskInput

  useEffect(() => {
    setOpts(maskOptions)
  }, [maskOptions])

  const handleRefs = (instance) => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(instance)
      } else {
        ref.current = instance
      }
    }

    if (instance) {
      inputMaskRef.current = instance
    }
  }
  return <CFormInput ref={handleRefs} {...rest} />
})

export default CMaskInput
