import React from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { useAuthUser, useAxios, useCashierNotification } from '@/hooks'
import { cilPowerStandby, cilUser, cilCog } from '@coreui/icons'
import { useNavigate } from 'react-router-dom'
import CIcon from '@coreui/icons-react'

const AppHeaderDropdown = () => {
  const { stopListen } = useCashierNotification()
  const { logOut } = useAuthUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const axiosSetUp = useAxios()

  const closeSession = () => {
    stopListen()
    logOut()
    navigate('/login')
    dispatch({ type: 'RESET_APP' })
    axiosSetUp.setUp()
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon icon={cilCog} size="xxl" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Configuración</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem component="button" onClick={closeSession}>
          <CIcon icon={cilPowerStandby} className="me-2" />
          Cerrar sesión
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
