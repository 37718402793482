import BaseService from '../baseService'

export default class CashCountService extends BaseService {
  baseUrl = 'Cashmax/'

  async getInitialCash() {
    return await super.getResource('GetInitialCash/')
  }

  async getLastCashCount(cashierId, type) {
    return await super.getResource(`GetLastCashCount/${cashierId}/Type/${type}`)
  }

  async getTreasuryCashier(cashierId) {
    return await super.getResource(`GetAllTreasuryCashierByCashierId/`, { cashierId: cashierId })
  }

  async getCashierRequests(pagination) {
    return await super.getResource(`GetCashierRequests/`, { ...pagination })
  }

  async getXStoreTransactions(pagination) {
    return await super.getResource(`GetXStoreTransactions/`, { ...pagination })
  }

  async existsXStorePendingTransactions(cashierId) {
    return await super.getResource(`ExistsXStorePendingTransactions/${cashierId}`)
  }

  async getIncomeByCashierId(queryParams) {
    return await super.getResource('GetIncomeByCashierId', queryParams)
  }

  async getCashierRequest(cashierRequestId) {
    return await super.getResource(`GetCashierRequest/${cashierRequestId}`)
  }

  async updateCashierRequest(data) {
    return await super.post(`${this.baseUrl}ChangeCashierRequestStatus/`, data)
  }

  async getCashierNotifications(cashierId) {
    return await super.getResource(`GetCashierNotifications/${cashierId}`)
  }

  async registerXStoreCode(data) {
    return await super.post(`${this.baseUrl}RegisterXStoreCode/`, data)
  }

  async verifySupervisorAccess(data) {
    return await super.post(`${this.baseUrl}VerifySupervisorAccess/`, data)
  }

  async updateCashierNotifications(data) {
    return await super.post(`${this.baseUrl}ChangeCashierNotificationsVisibility/`, data)
  }

  async addCashierNotifications(data) {
    return await super.post(`${this.baseUrl}AddCashierNotification/`, data)
  }

  async excecuteMoneyCount(action, data) {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return await super.post(`${this.baseUrl}ExcecuteMoneyCount/${action}`, data, config)
  }

  async addPOSTransaction(data) {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return await super.post(`${this.baseUrl}AddPOSTransaction`, data, config)
  }

  async saveEndowment(data) {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return await super.post(`${this.baseUrl}SaveEndowment`, data, config)
  }

  async createCashierRequest(data) {
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return await super.post(`${this.baseUrl}CreateCashRequest`, data, config)
  }
}
