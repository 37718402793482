import RemittanceBaseService from './remittanceBaseService'

export default class RemittanceCustomerService extends RemittanceBaseService {
  async get(identification) {
    return await super.get('catalog/customer', {
      identification,
    })
  }

  async create(customer) {
    return await super.create('catalog/customer', customer)
  }
}
