import React from 'react'
import PropTypes from 'prop-types'

import { CRow, CCol } from '@coreui/react'

const TransactionDetail = ({
  sender,
  receiver,
  id,
  transactionId,
  phone,
  currentDate,
  remittanceType,
  transactionType,
  destiny,
  source,
  senderAmount,
  receiverAmount,
  exchangeRate,
  senderCurrency,
  sourceDate,
  sourceCountry,
  destinationCity,
}) => (
  <>
    <CRow>
      <CCol xs={4} className="text-start">
        <small className="">Destinatario:</small>
      </CCol>
      <CCol xs={8} className="text-start">
        <small className=" fw-bolder">{receiver}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={3} className="text-start">
        <small className="">Remitente:</small>
      </CCol>
      <CCol xs={9} className="text-start">
        <small className=" fw-bolder">{sender}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={5} className="text-start pe-0">
        <small className="">N° Transacción:</small>
      </CCol>
      <CCol xs={7} className="text-start ps-0">
        <small className=" fw-bolder">{transactionId}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={2} className="text-start">
        <small className="">ID:</small>
      </CCol>
      <CCol xs={10} className="text-start">
        <small className=" fw-bolder">{id}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={2} className="text-start">
        <small className="">Tel:</small>
      </CCol>
      <CCol xs={10} className="text-start">
        <small className=" fw-bolder">{phone}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={2} className="text-start">
        <small className="">Fecha:</small>
      </CCol>
      <CCol xs={10} className="text-start">
        <small className=" fw-bolder">{currentDate}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={3} className="text-start">
        <small className="">Operador:</small>
      </CCol>
      <CCol xs={9} className="text-start">
        <small className="fw-bolder">{remittanceType}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={7} className="text-start">
        <small className="">Tipo de transacción:</small>
      </CCol>
      <CCol xs={4} className="text-start ps-0">
        <small className=" fw-bolder">{transactionType}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={2} className="text-start">
        <small className="">Destino:</small>
      </CCol>
      <CCol xs={10} className="text-start">
        <small className=" fw-bolder">{destiny}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={5} className="text-start">
        <small className="">País origen:</small>
      </CCol>
      <CCol xs={7} className="text-start p-0">
        <small className=" fw-bolder">{sourceCountry}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={3} className="text-start">
        <small className="">Origen:</small>
      </CCol>
      <CCol xs={8} className="text-start p-0">
        <small className=" fw-bolder">{source}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={5} className="text-start">
        <small className="">Departamento:</small>
      </CCol>
      <CCol xs={7} className="text-start">
        <small className=" fw-bolder">{destinationCity}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={6} className="text-start">
        <small className="">Cantidad a pagar:</small>
      </CCol>
      <CCol xs={2} className="text-start p-0">
        <small className=" fw-bolder">{receiverAmount}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={6} className="text-start">
        <small className="">Tasa de cambio:</small>
      </CCol>
      <CCol xs={2} className="text-start p-0">
        <small className=" fw-bolder">{exchangeRate}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={6} className="text-start">
        <small className="">Cantidad origen:</small>
      </CCol>
      <CCol xs={2} className="text-start p-0">
        <small className=" fw-bolder">{senderAmount}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={6} className="text-start">
        <small className="">Moneda origen:</small>
      </CCol>
      <CCol xs={2} className="text-start p-0">
        <small className=" fw-bolder">{senderCurrency}</small>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs={6} className="text-start">
        <small className="">Fecha de origen:</small>
      </CCol>
      <CCol xs={2} className="text-start p-0">
        <small className=" fw-bolder">{sourceDate}</small>
      </CCol>
    </CRow>
  </>
)

TransactionDetail.propTypes = {
  sender: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  remittanceType: PropTypes.string.isRequired,
  transactionType: PropTypes.string.isRequired,
  destiny: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  senderAmount: PropTypes.string.isRequired,
  receiverAmount: PropTypes.string.isRequired,
  exchangeRate: PropTypes.string.isRequired,
  senderCurrency: PropTypes.string.isRequired,
  sourceDate: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  sourceCountry: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
}

export default TransactionDetail
