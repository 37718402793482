import React from 'react'
import { CRow, CCol } from '@coreui/react'
import logo from '@/assets/brand/logo-remittance.png'

const VoucherHeader = () => (
  <>
    <CRow>
      <CCol xs={7} sm={6} className="text-start pe-0">
        <small className="">Perfil Integral del cliente</small>
      </CCol>
      <CCol xs={5} sm={6} className="ps-0 pe-4">
        <img src={logo} className="w-100" width={80} height={40} alt="Logo" />
      </CCol>
    </CRow>
  </>
)

export default VoucherHeader
