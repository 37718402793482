import RemittanceBaseService from './remittanceBaseService'

export default class RemittanceService extends RemittanceBaseService {
  async getRemittanceByCode(remittanceType, remittanceCode) {
    this.remittanceType = remittanceType
    return await super.get(`transfer/${remittanceCode}`)
  }

  async payRemittance(remittanceType, data) {
    this.remittanceType = remittanceType
    return await this.create(`transfer/pay`, data)
  }
}
