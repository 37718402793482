import React, { Suspense, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotification } from '@/hooks'
import { Route, Routes } from 'react-router-dom'
import { AxiosSetUpContextProvider, CashierNotificationProvider } from '@/contexts'
import './scss/style.scss'
import { setUpAxios } from '@/lib'
import { LOGIN } from '@/router'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

const App = () => {
  const navigator = useNavigate()
  const notification = useNotification()
  const [isLoaded, setIsLoaded] = useState(false)

  if (!isLoaded) {
    setIsLoaded(true)
    setUpAxios(navigator, notification)
  }

  return (
    <AxiosSetUpContextProvider>
      <CashierNotificationProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path={LOGIN} name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </CashierNotificationProvider>
    </AxiosSetUpContextProvider>
  )
}

export default App
