import React from 'react'
import { CRow, CCol } from '@coreui/react'

const ReportFooter = () => (
  <>
    <CRow>
      <CCol lg={12} className="text-center">
        <p className="fw-bolder">Para consultas, quejas o sugerencias llamar al:</p>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <p className="fw-bolder">+ (505) 2264 7484</p>
      </CCol>
      <CCol lg={12} className="text-center m-0">
        <p className="fw-bolder">clientes@credex.com.ni</p>
      </CCol>
    </CRow>
  </>
)

export default ReportFooter
